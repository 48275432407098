.container {
  width: 1029px;
  padding: 32px;

  color: var(--color-black);

  background-color: var(--color-white);
  border-radius: 12px;
}

@media (max-width: 1360px) {
  .container {
    width: 100%;
    max-width: 1029px;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  margin-top: 32px;
}

.item {
  display: flex;
  flex-basis: 49%;
  flex-direction: column;

  padding: 18px 16px 21px;

  border: 1px solid var(--color-gray-light);
  border-radius: 12px;

  transition: all 0.3s ease-in-out;

  &.selected {
    border: 1px solid var(--color-red);
    outline: 1px solid var(--color-red);
  }
}

@media (max-width: 1285px) {
  .item {
    flex-basis: 48%;
  }
}

@media (max-width: 1080px) {
  .item {
    flex-basis: 49%;
  }
}

@media (max-width: 975px) {
  .item {
    flex-basis: 100%;
  }
}


.title_container {
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';

    position: absolute;
    bottom: -8px;
    left: 0;

    width: 100%;
    height: 1px;

    opacity: 0.14;
    background-color: var(--color-black);
  }
}

.item_title {
  font-size: 15px;
  line-height: 133%;
  letter-spacing: 0.01em;
}


.item_text {
  margin-top: 24px;

  font-size: 15px;
  font-weight: 500;
  line-height: 133%;
  letter-spacing: 0.03em;
}

.info_container {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.item_info {
  font-size: 15px;
  font-weight: 500;
  line-height: 133%;
  letter-spacing: 0.03em;
}

.fullwidth {
  flex-basis: 100%;
  width: 100%;
}


.edit_icon {
  color: var(--color-gray);
  transition: color 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: var(--color-red);
  }
}

