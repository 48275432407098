.product_container_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.product_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  max-width: 850px;
  height: 70px;
}

.product_image {
  width: 64px;
  border-radius: 8px;
}

.product_text {
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 15px;
}

.product_title {
  font-family: var(--roboto), serif;
  font-size: var(--fz-l);
  font-weight: var(--bold);
}

.supplier_info {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 5px;

  font-family: var(--roboto), serif;
  font-size: var(--fz-s);
  font-weight: var(--regular);
}

.grade {
  padding-right: 10px;
  padding-left: 7px;
}

.company_name {
  margin-right: 13px;
}

.arrow {
  margin-right: 40px;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
}
