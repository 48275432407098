@import 'src/common/styles/mixins';

.container {
  position: relative;
}

.wrapper {
  @include content_container;

  position: relative;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  padding-top: 2px;
  padding-bottom: 2px;
}

.nav_content {
  display: flex;
  column-gap: 10px;
  justify-content: space-around;

  border-right: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
}

@mixin laptop {
  .wrapper {
    grid-template: auto auto / auto auto;
  }

  .nav_wrapper {
    grid-column: 1 / span 2;
    grid-row: 1;
    padding: calc(max(8px, min(1.8%, 26px))) 0;
  }

  .nav_content {
    justify-content: space-between;
    border: none;
  }

  .button {
    justify-self: start;
  }

  .selected_wrapper {
    justify-self: end;
  }
}
