.button_sort {
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--medium);
}

.wrapper_head {
  display: flex;
  gap: 1px;
}
