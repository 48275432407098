.wrapper {
  display: flex;
  align-items: flex-end;
}

.select {
  column-gap: 12px;

  height: fit-content;
  padding: 0 10px;

  font-size: var(--fz-m);
  font-weight: var(--semi-bold);
}

.text_disabled {
  color: var(--color-gray-medium);
}
