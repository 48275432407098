@import "src/common/styles/mixins";

.container {
  @include content_container(40px);
}

.container_text {
  padding-top: 36px;
}

.title_block {
  margin: 10px 0;
}

.items {
  margin-bottom: 20px;
  font-size: var(--fz-l);
  line-height: 24px;
}

.text {
  line-height: 24px;

  &:last-child {
    margin-top: 20px;
  }
}