@import "src/common/styles/mixins";

.top_container,
.center_container,
.bottom_container {
  @include content_container;
}

.search {
  width: 100%;
}

.top_container,
.center_container,
.bottom_container {
  position: relative;

  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  padding: 12px 16px;
}

.center_wrapper {
  border-bottom: solid 1px var(--color-white-ivory);
}

.button_search {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
