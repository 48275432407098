.wrapper {
  display: flex;
  grid-area: personal_info;
  flex-direction: column;
  gap: 20px;

  padding: 32px;

  background: var(--color-white);
  border-radius: 12px;
}

.submit_button {
  width: 100%;
  margin-top: 16px;
}