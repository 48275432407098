@import "src/common/styles/mixins";

.header_wrapper {
  @include underlined;

  position: relative;
  background: var(--color-white);
}

.header_container {
  @include content_container;
}

.header_block {
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 26px 0;

  background-color: var(--color-white);
}