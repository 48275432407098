@import "src/common/styles/mixins";


.notifications_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notifications_item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  width: 100%;
  padding: 16px 0;

  border-bottom: 1px solid var(--color-white-ivory);
}

.notifications_item:last-child {
  border: none;
}