@import "src/common/styles/mixins";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  h2 {
    justify-self: start;
  }

  a {
    justify-self: end;
  }
}

.category_wrapper {
  display: flex;
  grid-column: 1/3;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}

.header_link {
  cursor: pointer;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  font-style: normal;
  line-height: 20px;
  color: var(--color-red);
  text-decoration-line: underline;

  background: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}

.orders_button {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  align-items: center;

  width: 100%;
  padding: 0;

  font-size: var(--fz-s);
  font-weight: var(--regular);

  &:hover {
    color: var(--color-red);
    text-decoration: underline;

  }
}

.icon {
  width: 48px;
  height: 48px;
}

@media only screen and (max-width: 560px) {
  .category_wrapper {
    flex-wrap: wrap;
  }

  .orders_button {
    width: 46%;
  }
}

@media only screen and (max-width: 485px) {
  .category_wrapper {
    button {
      font-size: var(--fz-s);
    }

    svg {
      width: 42px;
      height: 42px;
    }
  }
}