@import '/src/common/styles/mixins';

.buttons_wrapper {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.button {
  width: 150px;
  font-size: var(--fz-m);
  border: none;
}

@include mobile {
  .button {
    width: 120px;
  }
}
