.button_link {
  gap: 8px;
  color: var(--color-white);
  background-color: var(--color-green);

  &:hover {
    background-color: var(--color-green);
    filter: brightness(80%);
  }
}

.disabled {
  pointer-events: none;
  cursor: unset;
  opacity: 0.5;
  filter: grayscale(100%);
}

.icon {
  transform-origin: center;
  transform: scale(1.5);

  display: inline-block;

  width: auto;
  max-height: var(--fz-s);
}
