.loader_line {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 4px;

  background-color: rgba(252, 19, 61, 0.24);

  &::before {
    content: '';

    position: absolute;
    left: -50%;

    width: 40%;
    height: 4px;

    background-color: var(--color-red);

    -webkit-animation: line_animation 1s linear infinite;
    -moz-animation: line_animation 1s linear infinite;
    animation: line_animation 1s linear infinite;
  }
}

@keyframes line_animation {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}
