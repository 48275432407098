.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item {
  position: relative;

  display: flex;
  gap: 2px;

  font-size: var(--fz-m);
  font-weight: var(--medium);
}

.decor {
  align-self: flex-end;
  width: 58px;
  height: 1px;
  background: var(--color-black);
}

.delete {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    top: 50%;

    display: block;

    width: 100%;
    height: 1px;

    background: var(--color-red);
  }
}

.extra_discount {
  color: var(--color-red);
}