@import "src/common/styles/mixins";

.top_container,
.center_container {
  @include content_container;
}

.top_container,
.center_container {
  position: relative;
  padding: 12px 16px;
}
