.button {
  padding: 0;
  font-size: var(--fz-l);
  color: var(--color-black);

  &:hover {
    color: var(--color-red);
  }
}

.button_with_icon {
  display: flex;
  column-gap: 5px;
  align-items: center;

  padding: 0;

  color: var(--color-red);

  border: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}