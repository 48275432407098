@import 'src/common/styles/mixins';

.favorites_page {
  background-color: var(--color-white);
}

.container {
  @include content_container;
}

.top {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  padding: 15px 0 36px;

  text-align: center;
}

.search {
  width: 100%;
  max-width: 450px;
}

.empty_products_list {
  margin-top: 20px;
  text-align: center;
}

.main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  row-gap: 32px;
  column-gap: 10px;
  justify-items: center;

  margin-top: 24px;
  margin-bottom: 68px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.control_panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .main {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

@include mobile {
  .main {
    grid-template-columns: repeat(auto-fill, minmax(152px, 1fr));
  }

  .search {
    max-width: 390px;
  }
}

@media only screen and (max-width: 670px) {
  .search {
    max-width: 320px;
  }
}

@media only screen and (max-width: 508px) {
  .search {
    max-width: 240px;
  }
}

@media only screen and (max-width: 380px) {
  .top {
    flex-direction: column;
  }

  .search {
    max-width: 260px;
  }
}
