@import "src/common/styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_container {
  width: 634px;
  padding: 66px 0 32px;
}

.step {
  margin-bottom: 40px;
}

.subtitle {
  @include form_subtitle;
}

.add_logo {
  margin-top: 24px;
}
