@import '/src/common/styles/mixins';

.card_full {
  display: grid;
  grid-template-columns: minmax(152px, 220px) minmax(225px, 1fr);
  gap: 32px;
}

.card_info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  height: 100%;
}

.card_image {
  width: 100%;
}

.name {
  overflow: hidden;
  align-self: flex-start;

  margin-bottom: 6px;

  font-size: var(--fz-l-2);
  font-weight: var(--bold);
  line-height: 24px;
  color: var(--color-black);
  text-overflow: ellipsis;
  letter-spacing: 0.16px;
  white-space: nowrap;
}

.categories {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.category {
  font-size: var(--fz-xs-2);
  color: var(--color-red);
  letter-spacing: 0.16px;

  span {
    margin-right: 16px;
  }
}

.reviews_info {
  display: flex;
}

.reviews {
  font-size: var(--fz-xs);
  font-weight: var(--medium);
  color: var(--color-gray-medium);
  letter-spacing: 0.16px;
}

.orders_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

.orders {
  padding-left: 35px;

  font-size: var(--fz-s);
  font-weight: (var(--medium));
  color: var(--color-black);
  letter-spacing: 0.38px;
}

.supplier_wrapper {
  cursor: pointer;

  display: grid;
  grid-template-areas:
    "logo name"
    "logo details";
  column-gap: 12px;
  align-items: center;

  margin-bottom: 4px;
}

.supplier_logo {
  grid-area: logo;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.suppler_name_container {
  display: flex;
  grid-area: name;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: 180px;
  max-width: 220px;
}

.supplier_name {
  overflow: hidden;

  font-size: var(--fz-s);
  font-weight: var(--medium);
  text-overflow: ellipsis;
  letter-spacing: 0.16px;
  white-space: nowrap;
}

.supplier_arrow {
  transform: rotate(-90deg);
}

.suppler_details {
  grid-area: details;
  font-size: var(--fz-xs);
  color: var(--color-gray-medium);
  letter-spacing: 0.16px;
}

.price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  margin-bottom: 4px;

  > .rating {
    display: block;
    align-self: flex-end;

    font-size: var(--fz-xs);
    font-weight: var(--medium);
    color: var(--color-gray-medium);
  }
}

.amount {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: var(--fz-l);
  font-weight: var(--semi-bold);
  line-height: 100%;
  letter-spacing: 0.38px;
}

@media only screen and (max-width: 990px) {
  .card_image {
    max-width: 200px;
  }

  .supplier_wrapper {
    grid-template-areas:
      "logo name"
      "details details";
  }

  .orders_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .orders {
    padding: 0;
  }

  .card_full {
    gap: 16px;
  }
}

@include mobile {
  .orders_container {
    flex-direction: row;
    align-items: center;
  }

  .supplier_wrapper {
    grid-template-areas:
      "logo name"
      "logo details";
  }

  .orders {
    padding-left: 35px;
  }

  .card_full {
    gap: 32px;
  }
}

@media only screen and (max-width: 630px) {
  .orders_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .supplier_wrapper {
    grid-template-areas:
      "logo name"
      "details details";
  }

  .orders {
    padding: 0;
  }

  .card_full {
    gap: 16px;
  }
}
