.product_review_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 30px;
  padding-top: 30px;

  border-top: 2px solid #DDD;
}

.name_date_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 12px;
}

.name {
  margin-right: 16px;

  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--semi-bold);
  line-height: var(--fz-m);
}

.date {
  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.grades {
  margin-bottom: 12px;
  padding-left: 0;
}

.text {
  margin-bottom: 16px;
  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.photo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 106px;
    height: 106px;
    margin: 0 16px 16px 0;
    border-radius: 8px;
  }
}


