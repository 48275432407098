@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
  font-family: 'SF Pro Display Regular';
  src: url('assets/fonts/SFProDisplay-Regular.ttf');
}

@font-face {
  font-family: 'SF Pro Display Black';
  src: url('assets/fonts/SFProDisplay-Black.ttf');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url('assets/fonts/SFProDisplay-Bold.ttf');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  src: url('assets/fonts/SFProDisplay-Medium.ttf');
}

@font-face {
  font-family: 'SF Pro Display Light';
  src: url('assets/fonts/SFProDisplay-Light-1.ttf');
}

@font-face {
  font-family: 'SF Pro Display RegularItalic';
  src: url('assets/fonts/SFProDisplay-RegularItalic.ttf');
}

:root {
  /* -------colors------ */
  --color-black: #000;
  --color-gray-dark: #4E4E4E;
  --color-gray-medium: #828282;
  --color-gray: #999;
  --color-gray-light: #D4D4D4;
  --color-white-ivory: #F4F4F4;
  --color-white-snow: #F9F9F9;
  --color-white: #FFF;
  --color-orange:#FFAB5E;
  --color-red: #FC133D;
  --color-red-medium: #CA0F31;
  --color-red-dark: #B00D2B;
  --color-red-light: #FC133D33;
  --color-green-lime: #34E145;
  --color-green: #1C9929;

  /* -------font-sizes------ */
  /* -- font-size: calc(fz-min + (fz-max - fz-min) * (100vw - minViewPort) / (maxViewPort - minViewPort)); -- */
  --fz-xxs: 10px;     /* Extra Extra Small */
  --fz-xs: 12px;      /* Extra Small */
  --fz-xs-2: calc(12px + 1 * (100vw - 425px) / 1015);    /* Extra Small 2 */
  --fz-s: 14px;       /* Small */
  --fz-m: 16px;       /* Medium */
  --fz-l: calc(16px + 2 * (100vw - 425px) / 1015);       /* Large */
  --fz-l-2: calc(18px + 4 * (100vw - 425px) / 1015);     /* Large 2 */
  --fz-xl: calc(18px + 6 * (100vw - 425px) / 1015);      /* Extra Large */
  --fz-xl2: calc(22px + 4 * (100vw - 425px) / 1015);     /* Extra Large 2 */
  --fz-xxl: calc(22px + 12 * (100vw - 425px) / 1015);     /* Extra Extra Large */
  --fz-xxxl: calc(24px + 16 * (100vw - 425px) / 1015);    /* Extra Extra Extra Large */

  /* -------font-weights------ */
  --roboto: 'Roboto';
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;

  /*----------sizes----------*/
  --form-column-gap: 16px;
  --form-row-gap: 20px;
  --main-content-width: 1440px;
}

* {
  margin: 0;
  padding: 0;

  text-decoration: none;
  list-style-type: none;

  border: 0;
}

*, *::before,
*::after {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
}

*:focus,
*:active {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

/* stylelint-disable selector-no-qualifying-type */
input[type='number'] {
  appearance: textfield;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: var(--regular);
  font-style: normal;
  line-height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
a {
  color: inherit;
}

@media (min-width: 1440px) {
  :root {
    --fz-xxs: 10px;
    --fz-xs-2: 13px;
    --fz-s: 14px;
    --fz-m: 16px;
    --fz-l: 18px;
    --fz-l-2: 22px;
    --fz-xl: 24px;
    --fz-xl2: 26px;
    --fz-xxl: 34px;
    --fz-xxxl: 40px;
  }
}

@media (max-width: 425px) {
  :root {
    --fz-xxs: 10px;
    --fz-xs-2: 12px;
    --fz-s: 12px;
    --fz-m: 14px;
    --fz-l: 16px;
    --fz-l-2: 18px;
    --fz-xl: 18px;
    --fz-xl2: 22px;
    --fz-xxl: 22px;
    --fz-xxxl: 24px;
  }
}