.image_wrapper {
  cursor: pointer;
  position: relative;
  aspect-ratio: 1/1;
  width: 100%;

  &.hover_visible .hover {
    opacity: 1;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Darken the background */
  border-radius: 8px;

  transition: opacity 0.3s ease;
}

.hover_text {
  display: flex;
  gap: 10px;
  align-items: center;

  font-family: Roboto, sans-serif;
  font-size: var(--fz-l-2);
  font-weight: 600;
  line-height: 27px;
  color: #FFF;
}

.flag {
  position: absolute;
  z-index: 11;
  top: 7.3%;
  right: 7.3%;
}
