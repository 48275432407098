.link {
  display: inline-block;

  height: fit-content;

  font-weight: var(--bold);
  line-height: 100%;
  color: var(--color-red);

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}

.s {
  font-size: var(--fz-xl2);
}

.m {
  font-size: calc(34px + 6 * (100vw - 320px) / 1015);
}

@media (min-width: 1440px) {
  .m {
    font-size: 40px;
  }
}