@import '/src/common/styles/mixins';

.orders_wrapper {
  background-color: var(--color-white-snow);
}

.orders_container {
  max-width: var(--main-content-width);
  margin: 0 auto;
  padding: 40px 32px;
}

@include mobile {
  .orders_container {
    padding-left: 0;
  }
}
