.requirement {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;



  white-space: pre;
}



.requirement_not_met {
  display: block;

  width: 8px;
  height: 8px;

  background: #FF4040;
  border-radius: 6px;
}

.requirement_met {
  display: block;

  width: 8px;
  height: 8px;

  opacity: 1;
  background: var(--color-green-lime);
  border-radius: 6px;
}