.label {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;

  white-space: nowrap;
}

.label_text {
  font-size: var(--fz-xs-2);
  color: #828282;
}