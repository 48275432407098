.link_container {
  display: flex;
  flex-flow: column;

  padding: 32px;

  background: var(--color-white);
  border-radius: 12px;

  .link_description {
    font-size: var(--fz-xs-2);
  }
}

.link_description {
  margin-top: 4px;
  color: var(--color-black);
  text-align: left;
}

