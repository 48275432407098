@import '/src/common/styles/mixins';

.product_list_page {
  position: relative;

  display: flex;
  gap: 74px;

  max-width: var(--main-content-width);
  margin: 0 auto;
  padding: 48px 30px 96px;
}

@mixin laptop {
  .product_list_page {
    gap: 54px;
  }
}

@include mobile {
  .product_list_page {
    padding: 18px 16px 56px;
  }
}
