.product_size_container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  min-width: 268px;

}
