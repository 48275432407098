.text_wrapper {
  position: relative;
}

.title {
  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  text-align: left;
}

.description {
  margin-top: 16px;
  font-size: var(--fz-s);
  text-align: left;
}

.step_wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.step {
  margin-bottom: 6px;

  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  color: var(--color-gray-light);
  text-align: right;
}

.link {
  color: var(--color-red);
  text-decoration: underline;
}
