.items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;

  //min-width: 288px;
  margin-bottom: 40px;
}

.item {
  font: var(--regular) var(--fz-s) / 20px var(--roboto);
  color: var(--color-red);
  letter-spacing: 0.16px;
}

.link {
  color: inherit;
}