.button {
  width: 72px;
  height: 30px;
  
  color: var(--color-gray-medium);
  
  background: var(--color-white-ivory);
  border-radius: 8px;
  
  &.active {
    color: var(--color-red);
    background-color: var(--color-white);
    border: 1px solid var(--color-red);
  }
}

