.filter_button {
  cursor: pointer;

  display: flex;
  align-items: center;

  min-width: 250px;
  height: 30px;
  padding: 28px 0 28px 16px;

  border-radius: 6px;

  transition: all .1s;

  &:hover {
    background-color: var(--color-white-ivory);
  }

  &:has(.active_button) {
    background-color: var(--color-white-ivory);
  };
}

.button_categories {
  cursor: pointer;

  font-size: var(--fz-l-2);
  font-weight: var(--semi-bold);

  background: transparent;

  transition: all .1s;
}

.active_button {
  color: var(--color-red);
}
