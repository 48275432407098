@import 'src/common/styles/mixins';

.main_info,
.company_info,
.contacts_info {
  display: flex;
  flex-direction: column;
  row-gap: var(--form-row-gap);
  margin: 24px 0 36px;
}

.subtitle {
  @include form_subtitle;
}

.select_info_inputs {
  display: flex;
  gap: var(--form-column-gap);
}

.contacts_inputs {
  display: flex;
  flex-direction: column;
  gap: var(--form-row-gap);
  margin-bottom: 36px;
}

.button {
  width: 100%;
}

.select {
  background: var(--color-white-ivory);
  border-radius: 12px;
}

@media only screen and (max-width: 1020px) {
  .select_info_inputs {
    flex-direction: column;
  }
}

@include mobile {
  .select_info_inputs {
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
  .select_info_inputs {
    flex-direction: column;
  }
}
