@import 'src/common/styles/mixins';

.wrapper {
  @include underlined;

  position: relative;
  display: flex;
  align-items: center;
  height: 96px;
}

.mobile_container {
  height: auto;
  padding: 8px 0;
}

.container {
  @include content_container;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-top: calc(max(8px, min(1.8%, 26px)));
  padding-bottom: calc(max(8px, min(1.8%, 26px)));

  background-color: var(--color-white);
}

.logo {
  display: flex;
  align-items: center;
}

.vertical_line {
  display: inline-block;

  width: 2px;
  height: 17px;
  margin: 0 12px;

  background-color: var(--color-black);
}

.supplier_link {
  font-size: var(--fz-l-2);
  font-weight: var(--semi-bold);
  color: var(--color-black);
  letter-spacing: 0.38px;
}

.inner_buttons {

  @include mobile {
    gap: 17px;
  }

  display: flex;
  gap: 24px;
  align-items: center;
}

.menu_button {
  position: relative;

  display: flex;
  gap: 10px;
  align-items: center;

  font-size: var(--fz-m);
  line-height: calc(20 / 15);
  letter-spacing: 0.16px;

  background: transparent;
}

.company_logo_wrapper {
  overflow: hidden;
  flex: 0 0 40px;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  &:focus-visible {
    outline: 2px solid var(--color-gray-medium);
  }
}

.company_logo_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu_icons {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
}

.arrow {
  transform: rotate(180deg);
}

.business_name {
  margin: 0 8px 0 0;
  letter-spacing: 0.16px;
}
