.params_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
}

@media (max-width: 634px) {
  .params_container {
    flex-direction: column;
    row-gap: 24px;
  }
}