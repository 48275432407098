.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.title {
  font-size: var(--fz-l-2);
  font-weight: var(--semi-bold);
  letter-spacing: 0.38px;
}

.tags_properties_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  margin: 8px 0;

  text-wrap: nowrap;
}

.tags_variations_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  margin: 8px 0;

  text-wrap: nowrap;
}
