.menu_active {
  z-index: 10;
  opacity: 1;
}

.menu_main {
  top: 60px;
}

.menu_profile {
  top: 55px;
}

.menu {
  position: absolute;
  z-index: 10;
  /* top: 60px; */
  right: -72px;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 165px;
  padding: 24px;

  font-family: var(--roboto);
  font-size: var(--fz-l);
  font-weight: var(--medium);
  font-style: normal;
  line-height: 24px;
  color: var(--color-black);

  opacity: 0;
  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}

.menu_active {
  z-index: 999;
  transform: translateY(0);

  visibility: visible;
  opacity: 1;

  transition: 0.5s ease;
}

.menu_inactive {
  z-index: 10;
  transform: translateY(-20px);

  visibility: hidden;
  opacity: 0;

  transition: 0.5s ease;
}

.menu_supplier {
  right: 3.5px;
}

.menu_main {
  top: 60px;
}

.menu_profile {
  top: 55px;
}

.item {
  word-break: break-word;
}

.item a {
  color: inherit;
  transition: all 0.3s;
}

.item a, .item button {
  color: inherit;
  transition: all 0.3s;

  &:hover, &:focus-visible {
    color: var(--color-red);
    outline: none;
  }
}