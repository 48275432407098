@import 'src/common/styles/mixins';

@include auth_pages_form;

.buttons_container_user_role {
  display: flex;
  width: 100%;
  border: 1px solid var(--color-gray-light);
  border-radius: 12px;
}

.button_user_role {
  width: 50%;
}

.input_wrapper {
  input {
    padding-right: 46px;
  }
}

.buttons_container_alternate_signup {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 24px;
}
