@import "src/common/styles/mixins";

.wrapper {
  height: 100%;
  background: var(--color-white-snow);
}

.content_container {
  @include content_container;
}
