@import '/src/common/styles/mixins';

.grade {
  margin-bottom: 14px;
  padding-left: 0;
}

@include mobile-small {
  .grade {
    column-gap: 0;
    justify-content: space-between;
  }
}
