.main {
  user-select:none;

  position: absolute;
  z-index: 100;

  overflow: auto;

  width: 100%;
  max-height: 200px;

  background-color: var(--color-white);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 7px 6px 5px,rgba(0, 0, 0, 0.2) -4px 6px 5px;

  &::-webkit-scrollbar {
    width: 8px;
    border: 3px solid var(--color-black); /* the width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-white);
    box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-dark) ;    /* slider color */
    border: 2px solid var(--color-white-ivory);  /* margin around slider */
    border-radius: 20px;       /* rounded runner */
  }

  &.droponup {
    bottom: 48px;
    border-radius: 8px 8px 0 0;
    box-shadow: rgba(0, 0, 0, 0.2) 7px -3px 5px,rgba(0, 0, 0, 0.2) -4px -2px 5px;
  }
}

