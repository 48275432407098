.wrapper {
  display: flex;
  align-items: center;
}

.description {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.img_wrapper {
  position: relative;
}

.wrapper .logo_img,.wrapper .avatar_img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.focus_img {
  width: 78px;
  height: 78px;

  object-fit: cover;
  border-radius: 50%;
  outline: 2px solid var(--color-gray-dark);
}


.default_img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.label {
  cursor: pointer;

  display: flex;

  font-family: var(--roboto);
  font-size: var(--fz-m);
  font-weight: var(--regular);
  font-style: normal;
  line-height: 20px;
  color: var(--color-red);

  &:hover {
    color: var(--color-red-medium);
    text-decoration: underline;
  }
}

.placeholder {
  max-width: 180px;
  margin-top: 9px;
  line-height: 12px;
  color: var(--color-gray-medium);
}

.input_default {
  cursor: pointer;

  position: absolute;
  z-index: 2;

  width: 120px;
  height: 120px;

  opacity: 0;
  border-radius: 8px;

  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.input_disabled, .label_disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input_logo, .input_avatar {
  cursor: pointer;

  position: absolute;
  z-index: 2;

  width: 78px;
  height: 78px;

  opacity: 0;
  border-radius: 50%;

  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}


.logo_cross,
.avatar_cross {
  cursor: pointer;

  position: absolute;
  top: -5px;
  right: -10px;

  background: none;
}

.default_cross {
  cursor: pointer;

  position: absolute;
  z-index: 5;
  top: 5px;
  right: 5px;

  background: none;
}
