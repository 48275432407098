@import '/src/common/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //gap: 16px;
  //
  //width: 100%;
  //height: auto;
  margin-top: 24px;
  margin-bottom: 20px;
}

.button {
  cursor: pointer;

  padding: 0;

  font-size: var(--fz-m);
  color: var(--color-red);
  text-align: left;
  text-decoration: underline;

  background: transparent;
  border: none;
  outline: none;

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }

  &:disabled {
    color: var(--color-gray-medium);
  }
}

.percentage {
  margin-bottom: 20px;
  font-size: var(--fz-xs);
}

.additional {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

@include mobile {
  .container,
  .additional {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
