.container {
  display: flex;
  grid-area: Grades;
  flex-basis: 50%;
  column-gap: 8px;
  align-items: center;
  justify-self: flex-start;
}

.text_container {
  display: flex;
  column-gap: 5px;
  align-items: center;

  font-size: var(--fz-s);
  color: var(--color-gray-medium);
  letter-spacing: 0.16px;
}

.deals {
  column-gap: 16px;
}

.is_gap {
  column-gap: 5px
}