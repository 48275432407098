@import 'src/common/styles/mixins';

@include seller_section();

.supplier_info_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section {
  margin-bottom: 16px;
}

.arrow {
  cursor: pointer;
  transform: rotate(270deg);

  svg {
    width: 25px;
    height: 25px;
  }
}

.supplier_info {
  display: flex;
  gap: 16px;
  align-items: center;
}

.flex_column_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.logo {
  width: 64px;
  height: 64px;
}

.grades {
  padding-left: 0;
}
