.variations_tag_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.properties_tag {
  cursor: pointer;

  display: inline-flex;
  align-items: center;

  min-width: fit-content;
  max-width: 33%;
  padding: 4px 16px;

  font-size: 14px;
  color: var(--color-gray-medium);
  white-space: nowrap;

  background: var(--color-white-ivory);
  border-radius: 4px;

  &.active {
    padding: 2.8px 15px;
    color: var(--color-red);
    background-color: var(--color-white);
    border: 1px solid var(--color-red);
  }
}

.variations_tag {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 60px;
  padding: 4px 8px;

  font-size: 14px;
  color: var(--color-gray-medium);
  white-space: nowrap;

  background: var(--color-white-ivory);
  border-radius: 4px;

  &.active {
    padding: 2.8px 7px;
    color: var(--color-red);
    background-color: var(--color-white);
    border: 1px solid var(--color-red);
  }
}
