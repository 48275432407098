.phone_number {
  display: flex;
  gap: 12px;
  align-items: center;

  height: 33px;

  font-size: var(--fz-xl);
  font-weight: var(--medium);
  color: inherit;

  transition: all 0.4s;

  &:hover,
  &:active {
    transform: scale(1.05);
  }

  &:focus-visible {
    border-radius:4px;
    outline: 1px solid var(--color-gray-medium);
  }
}

