@import "../../../../../common/styles/mixins";

@include seller_section();

.bundle_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;
}

.bundle_container {
  overflow: hidden;
  display: flex;
  flex-basis: 100%;
  gap: 20px;

  text-align: center;
}

.bundles {
  display: flex;
  gap: 15px;
}

.item {
  cursor: pointer;

  display: flex;
  align-items: center;

  height: 32px;
  padding: 8px 32px 7px;

  color: var(--color-gray-medium);
  text-align: center;


  background-color: #F1F1F1;
  border-radius: 8px;
}

.left_arrow, .right_arrow {
  cursor: pointer;

  position: relative;

  width: 28px;
  height: 26px;

  background-color: transparent;
}

.left_arrow {
  z-index: 1;
  transform: rotate(90deg);
  margin-right: 14px;
  box-shadow: 0 -10px 15px 20px var(--color-white);
}

.right_arrow {
  z-index: 1;
  transform: rotate(-90deg);
  box-shadow: 0 -10px 15px 20px var(--color-white);
}

.right_arrow::after {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;


}

.active {
  color: var(--color-white);
  background-color: var(--color-red);
}