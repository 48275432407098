@import "src/common/styles/mixins";

.categories {
  scrollbar-width: none;

  overflow: -moz-scrollbars-none;
  overflow-y: scroll;
  display: grid;
  gap: 16px 0;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &.grab {
    cursor: grab;
  }

  &.grabbing {
    cursor: grabbing;
  }
}

.item {
  user-select: none;
  justify-content: space-around;
  min-width: 160px;
  text-align: center;
}

.button {
  cursor: pointer;

  font-size: var(--fz-l);
  font-weight: var(--semi-bold);
  color: var(--color-black);

  background: none;

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}

.categories.grab .button,
.categories.grabbing .button {
  cursor: inherit;
}

.btn_active {
  color: var(--color-red);
  transition: all 0.3s;
}

.border_line {
  height: 3px;
  background: var(--color-gray-light);
  border-radius: 8px;
}

.border_line_active {
  position: relative;
  height: 3px;
  background-color: var(--color-red);
  transition: all 0.3s;
}
