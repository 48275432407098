@import '/src/common/styles/mixins';

.wrapper {
  display: flex;
  gap: 32px;
}

@include mobile {
  .wrapper {
    gap: 24px;
  }
}

@include mobile-small {
  .wrapper {
    gap: 16px;
  }
}
