.category_list_wrapper {
  padding-top: 12px;
  padding-left: 22px;
}

.category_list_item {
  display: flex;
  gap: 8px;
  padding-bottom: 4px;

  > * {
    cursor: pointer;
  }
}

.title {
  cursor: pointer;

  display: flex;
  align-items: center;

  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.arrow {
  margin-top: 3px;
  transition: transform 0.5s;
}

.arrow_up {
  transform: rotate(180deg);
}

