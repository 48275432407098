.button {
  cursor: pointer;

  margin-right: 16px;

  font-family: var(--roboto);
  font-size: var(--fz-m);
  font-weight: var(--medium);
  color: var(--color-black);
  white-space: nowrap;

  background: transparent;

  &:active {
    color: var(--color-red-dark);
  }

  &:hover {
    color: var(--color-red-medium);
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}
