.main {
  position: relative;
  font-family: Roboto, sans-serif;
  font-size: var(--fz-s);
  border-radius: 12px;

  &:focus-visible {
    outline: 1px solid var(--color-gray-medium);
  }
}

.header {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 35px;
  padding: 24px;

  word-break: break-word;

  &.opened_menu_up_pos_header {
    box-shadow: rgba(0, 0, 0, 0.2) 4px -6px 5px, rgba(0, 0, 0, 0.2) -4px -6px 5px;
  }

  &.header_active_up {
    border-radius: 0 0 8px 8px;
  }

  &.header_active {
    cursor: pointer;
    user-select: none;

    z-index: 200;

    display: flex;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    width: 100%;
    height: 35px;
    padding: 24px;

    word-break: break-word;

    background: var(--color-white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.2) 7px 6px 5px, rgba(0, 0, 0, 0.2) -4px 6px 5px;
  }

  &:focus-visible {
    border-radius: 12px;
    outline: 1px solid var(--color-gray-medium);
  }

  &.focus_disabled {
    border-radius: 12px 12px 0 0;
    outline: none;
  }

  &.droponup {
    border-radius: 0 0 12px 12px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.2) 7px -3px 5px, rgba(0, 0, 0, 0.2) -4px -6px 5px;
  }

  &:active {
    outline: none;
  }

  &.header_disabled {
    pointer-events: none;
    cursor: default;

    color: var(--color-gray-medium);

    opacity: 0.5;
    border-radius: 8px;
  }
}

.error {
  position: absolute;
  bottom: -15px;
  left: 0;

  font-size: var(--fz-xs-2);
  color: var(--color-red);
}

.opened_menu_up_pos {
  border-radius: 8px 8px 0 0;
  box-shadow: rgba(0, 0, 0, 0.2) -4px -2px 5px, rgba(0, 0, 0, 0.2) 4px -2px 5px;
}

.menu_up_pos {
  bottom: 100%;
}

.closed_menu {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 7px 6px 5px, rgba(0, 0, 0, 0.2) -4px 6px 5px, rgba(0, 0, 0, 0.2) 0 -4px 5px;
}
