@import '/src/common/styles/mixins';

.name_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@include mobile {
  .name_container {
    flex-direction: row;
  }
}

@include mobile-small {
  .name_container {
    flex-direction: column;
  }
}
