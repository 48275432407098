@import 'src/common/styles/mixins';

.wrapper {
  padding: 60px 0 80px;
  background-color: rgba(252, 19, 61, 0.02);
}

.container {
  @include content_container;

  display: flex;
  justify-content: space-between;
}

.subscribe_title {
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
}

.subscribe_subtitle {
  font-size: var(--fz-xl);
  font-weight: var(--bold);
  line-height: 100%;
}

.input_box {
  display: grid;
  grid-template-columns: max(393px) 162px;
  gap: 12px;
  align-items: center;
}

.input_email {
  padding-top: 17px;
  padding-bottom: 16px;
}

.button_subscribe {
  font-size: var(--fz-m);
}

.phone_title {
  margin-bottom: 12px;
  color: var(--color-black);
}

.our_media {
  display: flex;
  gap: 25px;
  justify-content: space-around;
  width: 100%;
}

@mixin laptop {
  .container {
    gap: 30px;
    align-items: center;
    justify-content: space-around;
  }

  .phone_title {
    margin-bottom: 10px;
  }

  .social_network_wrapper > div > div {
    margin-bottom: 10px;
  }

  .our_media {
    flex-direction: column;
    align-items: start;
    width: 340px;
  }

  .subscribe_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 950px) {
  .wrapper {
    padding: 32px 0 40px;
  }

  .container {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  .feedback_wrapper,
  .social_network_wrapper > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .social_network_wrapper {
    .title {
      margin-bottom: 10px;
    }
  }

  .our_media {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
}

@include mobile {
  .input_box {
    button {
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .subscribe_wrapper {
    align-items: center;
  }

  .our_media {
    flex-direction: column;
    width: 280px;
  }

  .our_media > .feedback_wrapper,
  .social_network_wrapper > div {
    align-items: center;
    width: 100%;
  }

  .social_network_wrapper {
    width: 100%;
  }

  .input_box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;

    width: 100%;
    max-width: 340px;

    div > input {
      width: 100%;
      font-size: var(--fz-s);
    }

    button {
      font-size: var(--fz-s);
    }
  }
}
