.form_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form_container {
  width: 100%;
  max-width: 634px;
}

.form {
  margin-top: 32px;
}

.phone_input {
  max-width: 266px;
}

.submit_btn {
  width: 100%;
  margin-top: 12px;
}

.modal {
  position: relative;
  width: 698px;
  padding: 32px;
}


