@import "src/common/styles/mixins";

.wrapper {
  display: grid;
  grid-template:
    "header" auto
    "body" 1fr
    "footer" auto
    /minmax(320px, 1fr);
  min-height: 100vh;
}

.header {
  grid-area: header;
}

.body {
  grid-area: body;

  &.additional {
    background-color: var(--color-white-snow);
  }
}

.footer {
  grid-area: footer;
}

.displayed {
  height: auto;
}
