.header {
  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  line-height: 100%;
  color: var(--color-black);
  letter-spacing: 0.0024em;
}

.subheader {
  margin-top: 16px;

  font-size: var(--fz-s2);
  line-height: 24px;
  color: var(--color-gray-medium);
  text-align: center;
  letter-spacing: 0.16px;
}
