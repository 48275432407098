@import 'src/common/styles/mixins';

.footer_bottom_wrapper {
  display: flex;
  justify-content: center;
  height: 48px;
  font-size: var(--fz-s);
}

.footer_bottom {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  align-items: center;

  width: 100%;
  max-width: var(--main-content-width);
  height: 100%;
  padding: 0;
}

.footer_bottom_black {
  background-color: var(--color-black);

  .footer_bottom {
    @include content_container;
  }

  .link {
    color: var(--color-white);
    transition: all 0.3s;

    &:hover {
      color: var(--color-red-medium);
      text-decoration: underline;
      transition: all 0.3s;
    }

    &:active {
      color: var(--color-red-dark);
    }
  }

  .links_separator {
    color: var(--color-white);
  }

  .copyright {
    color: var(--color-white);
  }
}

.footer_bottom_white {
  background-color: var(--color-white);

  .footer_bottom {
    grid-template-columns: 1fr repeat(4, auto) 1fr;
  }

  .copyright {
    grid-column: 2 / 2;
    justify-self: end;
    padding-right: 10px;
  }

  .link {
    color: var(--color-red);
    transition: all 0.3s;

    &:hover {
      color: var(--color-red-medium);
      text-decoration: underline;
      transition: all 0.3s;
    }

    &:active {
      color: var(--color-red-dark);
    }
  }
}

@include mobile-small {
  .footer_bottom_black .footer_bottom {
    grid-template-columns: 1fr repeat(2, auto) 1fr;
  }
}
