@import 'src/common/styles/mixins';

.images_box {
  text-transform: uppercase;
}

.container {
  @include content_container;
}

.image_box_main {
  position: relative;

  width: 100%;
  max-width: var(--main-content-width);
  height: auto;
  margin: 0 auto 32px;
}

.main_block {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);

  padding: 0.3em 0.94em 0.23em 0.26em;

  font-family: var(--roboto), sans-serif;
  font-size: 106px;
  color: var(--color-white);

  background-color: var(--color-red);
  border-radius: 0.38em;
}

.main_block_text:first-child {
  display: block;
  font-weight: var(--bold);
  line-height: 117%;
}

.main_block_text:nth-child(2) {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 0.59em;
  font-weight: var(--semi-bold);
  line-height: 126%;
}

.main_block_text:last-child {
  font-size: 0.19em;
  font-weight: var(--semi-bold);
  line-height: 133%;
}

.image_box_grid {
  display: grid;
  grid-template:
    'item1 item2 item2' auto
    'item1 item3 item4' 1fr / 2fr 1fr 1fr;
  gap: 12px;
  font-size: 50px;
}

.border_radius {
  border-radius: 12px;
}

.grid_item1,
.grid_item2,
.grid_item3,
.grid_item4 {
  position: relative;
  font-family: var(--roboto), sans-serif;
  color: var(--color-red);
}

.grid_item1_block,
.grid_item2_block,
.grid_item3_block,
.grid_item4_block {
  position: absolute;
  font-weight: var(--bold);
}

.grid_item1 {
  grid-area: item1;
}

.grid_item1_block {
  top: 34%;
  left: 5.4%;
  max-width: 240px;
  line-height: 85%;
}

.grid_item2 {
  grid-area: item2;
}

.grid_item2_block {
  top: 50%;
  right: 9.2%;
  transform: translate3d(0, -54%, 0);
  max-width: 230px;
}

.grid_item2_text:first-child {
  font-size: 0.48em;
  font-weight: var(--medium);
  line-height: 85%;
}

.grid_item2_text:last-child {
  display: block;
  font-size: 0.9em;
  line-height: 85%;
}

.grid_item3 {
  grid-area: item3;
}

.grid_item4 {
  grid-area: item4;
}

.grid_item3_block,
.grid_item4_block {
  top: 24.6%;
  left: 6%;

  max-width: 160px;

  font-size: 0.6em;
  line-height: 85%;
}

@mixin laptop {
  .main_block {
    font-size: 82px;
  }

  .image_box_grid {
    font-size: var(--fz-xxxl);
  }
}

@media only screen and (max-width: 900px) {
  .main_block {
    font-size: 72px;
  }

  .image_box_grid {
    font-size: var(--fz--xxl);
  }

  .grid_item3_block,
  .grid_item4_block {
    max-width: 70px;
  }

  .grid_item1_block {
    max-width: 150px;
  }
}

@include mobile {
  .main_block {
    top: auto;
    right: auto;
    bottom: 32px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    font-size: 62px;
  }

  .image_box_main {
    height: 385px;
    margin: 0 auto 16px;

    img {
      object-position: 15%;
    }
  }

  .image_box_grid {
    grid-template:
      'item1 item1' 210px
      'item2 item2' 160px
      'item3 item4' 160px / 1fr 1fr;
    gap: 8px;
  }

  .grid_item3_block,
  .grid_item4_block {
    top: 13%;
    left: 5%;
  }
}

@include mobile-small {
  .image_box_grid {
    grid-template:
      'item1 item1' 184px
      'item2 item2' 123px
      'item3 item4' 123px / 1fr 1fr;
    font-size: 25px;
  }
}
