@import "src/common/styles/mixins";

.container {
  position: relative;
}

.wrapper {
  @include content_container;

  position: relative;
  align-items: center;
  padding: 0 2px;
}

.nav_container {
  @include content_container;

  padding: 16px 32px;
}
