.order_items_details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;

  margin-bottom: 32px;
}

.cart_list {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  background: var(--color-white);
  border-radius: 8px;
}

