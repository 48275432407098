.item {
  cursor: pointer;

  display: flex;
  column-gap: 10px;

  padding: 14px;

  word-break: break-word;
}

.item_selected {
  color: var(--color-white);
  background-color: var(--color-gray-medium);
}

.item_hover {
  cursor: pointer;

  padding: 14px;

  color: var(--color-white);
  word-break: break-word;

  background-color: var(--color-gray-dark);
}

.image {
  width: 20px;
}
