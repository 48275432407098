.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 50px 0
}


.filter_buttons {
  justify-content: flex-start;
}

.filter_button {
  width: 175px;
  height: 45px;
  margin: 0 15px 15px 0;

  color: var(--color-black);

  background: var(--color-white-snow);
  border-radius: 8px;

  &:hover {
    background: var(--color-gray-light);
  }
}

.active_button {
  border: 2px solid var(--color-red);
}

.filter_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.grades {
  display: flex;
  flex-direction: row;
  column-gap: 0;
  justify-content: flex-start;

  padding-left: 0;

  div {
    font-family: var(--roboto), sans-serif;
    font-size: var(--fz-xxl);
    font-weight: var(--bold);
    line-height: var(--fz-xxl);
    color: var(--color-black);
  }

  span {
    margin-left: 10px;
    font-family: var(--roboto), sans-serif;
    font-size: var(--fz-m);
    font-weight: var(--regular);
  }
}

.checkbox {
  margin-right: 10px;
}