@import '/src/common/styles/mixins';

.link {
  pointer-events: all;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  background: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.32);

  transition: all 0.4s;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.32);
  }
}

.wrapper {
  pointer-events: none;

  position: fixed;
  z-index: 100;
  bottom: 20px;

  display: flex;
  justify-content: right;

  width: 100%;
  max-width: var(--main-content-width);
  padding-right: 46px;
}

.icon {
  display: block;
  width: 40px;
  height: 40px;
}

@include mobile {
  .link {
    width: 64px;
    height: 64px;
  }
}
