.wrapper {
  position: relative;
  display: inline-block;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 13px 35px 13px 48px;

  font-size: var(--fz-s);
  font-weight: var(--medium);
  line-height: 100%;

  background: var(--color-white-ivory)
    url('../../assets/icons/files/magnifier-dark-grey.svg') no-repeat 17px center;
  border-radius: 12px;
  outline: none;

  &:focus-visible {
    outline: 1px solid var(--color-gray-medium);
  }

  &:disabled {
    color: var(--color-gray-medium);
    opacity: 0.5;
  }
}

.input::placeholder {
  color: #828282;
}

.button {
  cursor: pointer;

  position: absolute;
  top: 14px;
  right: 19px;

  width: 18px;
  height: 16px;

  background-image: url('../../assets/icons/files/header-search-bar-image.svg');
}

.button_cross {
  cursor: pointer;

  position: absolute;
  top: 13px;
  right: 19px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.2;
  }
}

.photo_search {
  display: none;
}

.cross_icon {
  width: 15px;
  height: 15px;
}
