@import '/src/common/styles/mixins';

.filter_container {
  display: flex;
  gap: 68px;
}

.filter {
  all: unset;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: var(--fz-m);
  font-weight: var(--regular);
  color: var(--color-black);
  white-space: nowrap;

  transition: 0.3s;

  &:active {
    color: var(--color-red-dark);
  }

  &:hover {
    color: var(--color-red-medium);
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }
}

.active_filter {
  color: var(--color-red);

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }

  &:hover {
    color: var(--color-red);
    text-decoration: none;
  }
}

.select_filter {
  display: flex;
  justify-content: space-between;

  width: 200px;
  padding: 0 10px;

  background-color: var(--color-white-ivory);
  border-radius: 10px;
}

.select_menu {
  top: 40px;
  left: 0;
  background-color: var(--color-white-ivory);
}

@include mobile-small {
  .select_filter {
    width: 165px;
  }
}
