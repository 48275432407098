@import "src/common/styles/mixins";

.container {
  @include content_container(40px);
}

.last_news {
  margin-bottom: 36px;

  font-size: var(--fz-xxl);
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.41px;
}

.container_block {
  padding: 32px;
  background-color: var(--color-white-ivory);
  border-radius: 12px;
}
