@import '/src/common/styles/mixins';

.main_info {
  width: 634px;
  margin-top: 24px;
  margin-bottom: 40px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.main_product {
  margin-bottom: 16px;
}

.select_container {
  margin-bottom: 16px;
}

.main_select {
  color: var(--color-gray-medium);
  background: var(--color-white-ivory);
  border-radius: 12px;
}

.description {
  resize: none;

  width: 634px;
  height: 120px;
  margin-bottom: 16px;
  padding: 16px 14px;

  font-family: Roboto, sans-serif;
  font-size: var(--fz-s);
  color: var(--color-black);
  letter-spacing: 0.24px;

  background: var(--color-white-ivory);
  border-radius: 12px;
}

.overlay {
  cursor: pointer;

  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: calc(100% - 31px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.image_container:hover .overlay {
  opacity: 1;
}

@include mobile {
  .main_info {
    width: 100%;
    height: 100%;
  }

  .description {
    width: 100%;
    height: auto;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 20px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 320px) {
  .main_info {
    width: 100%;
    height: 100%;
  }

  .description {
    width: 100%;
    max-height: none;
  }

  .container {
    margin: 0 auto;
  }
}
