.wrapper {
  position: relative;
  z-index: 102;

  &:has(.mobile) {
    display: flex;
  }
}

.arrow {
  transform: rotate(180deg);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: var(--fz-l);
  font-weight: var(--medium);
  letter-spacing: 0.16px;
}

.burger_seller {
  cursor: pointer;

  position: relative;
  z-index: 10;

  width: 19px;
  height: 14px;

  background: transparent;

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  span,
  &::before,
  &::after {
    content: "";

    position: absolute;
    left: 0;

    width: 100%;
    height: 2px;

    background: var(--color-black);
  }

  span {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

.burger_supplier {
  cursor: pointer;

  position: relative;
  z-index: 10;

  display: flex;
  gap: 12px;
  align-items: center;

  background: transparent;
}

.close_button {
  &::after,
  &::before {
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(45deg);

    width: 18px;

    background: var(--color-red);
  }

  &::after {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }

  span {
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    width: 32px;
    height: 32px;

    background: var(--color-white);
    border-radius: 50%;
  }
}

.mobile_background {
  pointer-events: none;

  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  opacity: 0;
  background: var(--color-black);

  transition: opacity 0.3s linear;

  &.show {
    pointer-events: all;
    opacity: 0.5;
  }
}

.mobile {
  pointer-events: none;

  position: absolute;
  top: calc(100% + 20px);
  left: -6.5px;
  transform: translate3d(-150%, 0, 0);

  flex-direction: column;
  gap: 16px;
  align-items: start;

  padding: 24px 16px;

  opacity: 0;
  background: var(--color-white);
  border-radius: 12px;

  transition: opacity 0.3s linear, transform 0.3s ease-in-out;

  &.show {
    pointer-events: all;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}


@media only screen and (max-width: 350px) {
  .mobile {
    width: calc(100vw - 20px);
  }
}
