@import '/src/pages/supplier-pages/pages/SupplierProducts/ProductsList/AddNewProductPage/Pricing/helper/style/mixin';

@include item();

.pricing_container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 40px;
}

.input_wrapper {
  margin-bottom: 60px;
}
