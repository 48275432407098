.default {
  line-height: 100%;
}

.bold {
  font-weight: var(--bold);
}

.semi_bold {
  font-weight: var(--semi-bold);
}

.xl {
  font-size: var(--fz-xxxl);
}

.l {
  font-size: var(--fz-xxl);
}

.m {
  font-size: var(--fz-xl2);
}

.s {
  font-size: var(--fz-xl);
}

.xs {
  font-size: var(--fz-l-2);
}