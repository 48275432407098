.container {
  padding: 64px 32px;
}

.container_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_button {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  width: 100%;
  margin-top: 12px;
}

.buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 100%;
}

.button_whatsapp {
  width: 100%;
  max-width: 219px;
}

.button_red {
  width: 100%;
  max-width: 219px;
}

.button_back {
  width: 100%;
  max-width: 219px;
  background: #000;
}

.title {
  display: flex;

  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
}

.info_text {
  font-size: 34px;
  font-weight: 700;
  line-height: 34px;
}

.container_info {
  padding-top: 80px;
}

@media (max-width: 520px) {
  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .container_header {
    align-items: stretch;
  }
}
