.bundle_button_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 26px;
}

.bundle_button {
  margin-right: 5px;
}

.active {
  color: var(--color-white);
  background-color: var(--color-red);
}