@mixin input($width, $height, $borderRadius) {
  cursor: pointer;

  position: relative;

  width: $width;
  height: $height;

  appearance: none;
  background: var(--color-white-ivory);
  border: none;
  border-radius: $borderRadius;

  transition: 0.1s;

  &:focus-visible {
    outline: 1px solid var(--color-gray-medium);
  }

  &:disabled {
    opacity: 0.5;
    transition: 0.3s;
  }
}

$black-color: #000;

.label {
  cursor: pointer;

  display: inline-flex;
  gap: 15px;
  align-items: center;

  width: max-content;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  font-style: normal;
  line-height: 20px;
  color: var(--color-black);
  letter-spacing: -0.24px;
}

.input_notification {
  @include input(52px, 32px, 37px);

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: left;

  &::before {
    content: '';

    position: absolute;
    left: 4px;

    width: 26px;
    height: 26px;

    background: var(--color-white);
    border: 1px solid rgba($black-color, 0.04);
    border-radius: 50%;
    box-shadow: 0 3px 8px rgba($black-color, 0.15), 0 1px 1px rgba($black-color, 0.16),
      0 3px 1px rgba($black-color, 0.1);

    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &:checked::before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  &:checked {
    background: var(--color-red);
  }
}

.input_default {
  @include input(24px, 24px, 4px);

  position: relative;

  &:checked {
    background: var(--color-red);
  }

  &:checked::after {
    content: '';

    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-48deg);

    display: block;

    width: 16px;
    height: 8px;

    border: 2px solid var(--color-white);
    border-top: none;
    border-right: none;
  }
}



.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
