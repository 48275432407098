$breakpoints: (
  'mobile_sm': 425px,
  'mobile': 768px,
  'tablet': 992px,
  'laptop': 1140px,
  'pc': 1280px,
);

@mixin mobile-small {
  @media only screen and (max-width: map-get($breakpoints, 'mobile_sm')) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: map-get($breakpoints, 'mobile')) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: map-get($breakpoints, 'tablet')) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: map-get($breakpoints, 'laptop')) {
    @content;
  }
}

@mixin pc {
  @media only screen and (max-width: map-get($breakpoints, 'pc')) {
    @content;
  }
}

@mixin form_subtitle {
  text-align: left;
}

@mixin auth_pages_form {
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
    max-width: 396px;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .input_wrapper {
    width: 100%;
  }

  .button_submit {
    width: 100%;
  }
}

@mixin content_container($margin: 0) {

  @include mobile {
    padding: 0 16px;
  }

  width: 100%;
  max-width: var(--main-content-width);
  margin: $margin auto;
  padding: 0 30px;
}

@mixin seller_section {
  .section {
    margin-bottom: 10px;
    padding: 32px;
    background: var(--color-white);
    border-radius: 12px;
  }
}

@mixin account_layout {
  display: flex;
  grid-area: account_details;
  flex-direction: column;
  gap: 8px;
}

@mixin underlined {
  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    background: var(--color-white-ivory);
  }
}
