@import 'src/common/styles/mixins';

@include seller_section();

.wrapper {
  background: var(--color-white-ivory);
}

.personal_info {
  display: flex;
  grid-area: personal_info;
  flex-direction: column;
  gap: 20px;
}

.orders {
  grid-area: orders;
}

.seller_addresses {
  grid-area: seller_addresses;
}

.account_management {
  @include account_layout;

  grid-area: account_managment;
}

.notifications {
  display: flex;
  grid-area: notifications;
  flex-direction: column;
  gap: 20px;
}

.content_container {
  display: grid;
  grid-column-gap: 12px;
  grid-template-areas:
    'personal_info orders notifications'
    'personal_info seller_addresses notifications'
    'personal_info . notifications'
    'account_managment . .';
  grid-template-columns: minmax(auto, 30%) minmax(auto, 46%) minmax(auto, 24%);
  grid-template-rows: 25% 21% 16% 39%;

  max-width: var(--main-content-width);
  margin: 0 auto;
  padding: 12px 32px 17px;
}

@media only screen and (max-width: 1250px) {
  .content_container {
    grid-template-areas:
      'personal_info orders'
      'personal_info seller_addresses'
      'personal_info notifications'
      'account_managment notifications';
    grid-template-columns: minmax(auto, 40%) minmax(auto, 60%);
    grid-template-rows: 23% 20% 16% 42%;
    padding: 12px 16px;
  }

  .personal_info {
    grid-row: 1/4;
  }

  .orders {
    grid-row: 1/2;
  }

  .seller_addresses {
    grid-row: 2/3;
  }

  .notifications {
    grid-row: 3/5;
  }

  .account_management {
    grid-row: 4/5;
  }
}

@include mobile {
  .content_container {
    grid-template-areas:
      'personal_info personal_info'
      'orders orders'
      'seller_addresses seller_addresses'
      'notifications account_managment';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .personal_info {
    grid-row: 1/2;
  }

  .orders {
    grid-row: 2/3;
  }

  .seller_addresses {
    grid-row: 3/4;
  }

  .notifications {
    grid-row: 4/5;
  }

  .account_management {
    grid-row: 4/5;
  }
}

@media only screen and (max-width: 560px) {
  .content_container {
    grid-template-areas:
      'personal_info'
      'orders'
      'seller_addresses'
      'notifications'
      'account_managment';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
  }

  .personal_info {
    grid-row: 1/2;
  }

  .orders {
    grid-row: 2/3;
  }

  .seller_addresses {
    grid-row: 3/4;
  }

  .notifications {
    grid-row: 4/5;
  }

  .account_management {
    grid-row: 5/6;
  }
}
