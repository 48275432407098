@import '/src/common/styles/mixins';

.list_item {
  font-size: var(--fz-l);
  font-weight: var(--regular);
  font-style: normal;
  line-height: 22px;
}

.link {
  font-size: var(--fz-l);
  font-weight: var(--semi-bold);
  color: var(--color-black);

  &:hover {
    font-weight: var(--bold);
    color: var(--color-red);
    transition: all 0.1s;
  }
}

.focus_item {
  font-weight: var(--medium);
  color: var(--color-red);
}

.items_links {
  margin-top: 14px;
  transition: all 0.1s;

  &:hover {
    cursor: pointer;
    font-weight: var(--medium);
    color: var(--color-red);
  }
}

.items_container {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 32px;
  column-gap: 22px;

  min-width: 660px;
}

@mixin laptop {
  .items_container {
    margin-left: 18px;
  }
}

@include mobile {
  .items_container {
    grid-template-columns: 1fr 1fr;
    min-width: auto;
  }
}

@media only screen and (max-width: 460px) {
  .items_container {
    grid-template-columns: 1fr;
  }
}
