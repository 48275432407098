@import '/src/common/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: calc(100% - 450px);
  padding-right: 64px;
}

.control_panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view_switchers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.branch_crumbs {
  margin-left: 16px;
  font-size: var(--fz-m);
  font-weight: var(--semi-bold);
}

.list {
  display: grid;
  margin-top: 24px;
  margin-bottom: 68px;
}

.list_container {
  grid-template-columns: minmax(120px, 1fr);
  row-gap: 16px;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  row-gap: 32px;
  column-gap: 10px;
}

.info_button {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 35px;
}

.select {
  column-gap: 12px;
  font-size: var(--fz-m);
  font-weight: var(--semi-bold);
}

.loading_slider {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-top: 80px;
}

.filter {
  display: none;
}

.link {
  font-weight: var(--semi-bold);
  color: var(--color-black);

  &:hover {
    font-weight: var(--bold);
    color: var(--color-red);
    transition: all 0.1s;
  }
}

@mixin laptop {
  .wrapper {
    padding-right: 20px;
  }

  .branch_crumbs {
    display: none;
  }

  .grid_container {
    grid-template-columns: repeat(auto-fill, minmax(152px, 1fr));
  }
}

@include mobile {
  .grid_container {
    row-gap: 20px;
    column-gap: 6px;
    justify-items: center;
  }

  .filter {
    display: block;
  }

  .wrapper {
    padding-right: 0;
  }

  .branch_crumbs {
    display: none;
  }
}

.filter_icon circle:nth-of-type(1) {
  fill: var(--color-red);
}
