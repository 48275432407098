@import "src/common/styles/mixins";

.product_container {
  background: var(--color-white-ivory);
}

.wrapper {
  @include content_container;

  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: 51px;
  padding-top: 14px;
  padding-bottom: 100px;
}
