.product_color_container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;

  margin-bottom: 18px;
}


.active::before {
  content: '';

  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;

  width: 100%;
  height: 32px;

  border: 2px solid var(--color-red);
  border-radius: 8px;
}

@media (max-width: 1365px) {
  .item {
    width: 20%;
  }
}
