@import '/src/common/styles/mixins';

.wrapper {
  position: relative;
}

.container {
  display: flex;
  gap: 8px;

  font-size: var(--fz-m);
  font-weight: var(--medium);
  color: var(--color-black);
}

.select {
  font-size: var(--fz-m);
}

.select_box {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.select_title {
  white-space: nowrap;
}

.mobile_header {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
}

.arrow_up {
  transform: rotate(180deg);
}

.mobile {
  pointer-events: none;

  position: absolute;
  z-index: 5;
  top: calc(100% + 6px);
  right: -8px;

  flex-direction: column;
  gap: 0;
  align-items: end;

  padding: 5px;

  opacity: 0;
  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  &.show {
    pointer-events: all;
    opacity: 1;
  }
}

@include mobile-small {
  .currency {
    display: none;
  }
}
