
.dropdown {
  position: relative;

  margin: 0 auto;

  font-size: var(--fz-s);

  background: var(--color-white);
  border-radius: 12px;
}

.dropdown, .dropdown * {
  z-index: 1000;
}

.dropdown_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 260px;
  height: 48px;
}

.dropdown_content {
  position: absolute;
  z-index: 10000;
  top: 110%;
  left: 0;

  width: 100%;
  padding: 0 14px 12px;

  background: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.hidden_dropdown_content {
  display: none;
}

.filter_name {
  overflow: hidden;

  margin-right: 20px;

  font-weight:  var(--regular);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow_up {
  transform: rotate(180deg);
}

