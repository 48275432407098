.brand {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: var(--fz-l-2);
  font-weight: var(--semi-bold);
  letter-spacing: 0.38px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 259px;
}

.first_tags_container {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-height: 60px;
  margin: 8px 0;

  text-wrap: nowrap;
}

.all_tags_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  margin: 8px 0;

  text-wrap: nowrap;
}

.show_all_brands {
  cursor: pointer;

  display: block;

  width: fit-content;

  font-size: var(--fz-s);
  color: var(--color-red);
  letter-spacing: 0.16px;

  background: none;
  border-bottom: 1px solid var(--color-red);
}
