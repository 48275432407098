.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  background: transparent;
  border: none;
  outline: none;

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }

  &:disabled {
    color: var(--color-gray-light);
  }
}