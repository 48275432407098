.order_list_item {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  width: 100%;
  margin-bottom: 32px;
}

.product_info {
  display: flex;
  width: 100%;
}

.cart_controls {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.trash_button {
  align-items: flex-start;
  align-self: flex-end;
  margin-bottom: auto;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.8;
  }
}

.trash_icon {
  width: 22px;
  height: 22px;
}

.counter_wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.counter {
  width: 168px;
}

@media (max-width: 1040px) {
  .order_list_item {
    flex-wrap: wrap;
  }

  .product_info {
    margin-bottom: 16px;
  }

  .cart_controls {
    flex-direction: row-reverse;
    gap: 1rem;
    width: 100%;
    height: auto;
  }

  .trash_button {
    width: 20%;
    margin: 0;
  }

  .counter_wrapper {
    width: 80%;
    padding-top: 6px;
  }

  .counter {
    width: 100%;
  }
}