.wrapper {
  max-width: 464px;
  text-align: center;
}

.link {
  color: var(--color-red);
  text-decoration: underline;

  &:hover {
    color: var(--color-black);
  }
}
