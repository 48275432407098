.notice_container {
  cursor: default;

  position: fixed;
  z-index: 100;
  top: 30px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  display: flex;
  flex-shrink: 1;
  column-gap: 16px;
  align-items: center;

  width: calc(min(100%, 100vw - 32px));
  max-width: 400px;
  padding: 12px;

  font-size: var(--fz-s);
  color: var(--color-white);

  border-radius: 12px;
}

.success {
  background: var(--color-green-lime);
}

.error {
  background: var(--color-red);
}

.message {
  width: 100%;
}

.cross {
  cursor: pointer;

  flex-shrink: 0;
  align-self: flex-start;

  width: 30px;
  height: 30px;
  padding: 0 6px 6px;
}
