.arrow_up {
  transform: rotate(180deg);
}

.image {
  width: 20px;
}

.content_wrapper {
  display: flex;
  column-gap: 12px;
}