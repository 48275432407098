@import 'src/common/styles/mixins';

@include seller_section();

.product_details_container {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  min-width: 288px;
  max-width: calc(100vw - 64px);
}

.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100%;
  margin-bottom: 16px;
}

.product_title {
  margin-bottom: 34px;
}

.favorite_button {
  top: 14px;
}

.modal_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.modal_buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.loader_button {
  width: 100%;
}

@media (max-width: 1200px) {
  .product_details_container {
    padding: 0;
  }
}

@media (max-width: 783px) {
  .favorite_button {
    top: 14px;
  }
}

@include mobile {
  .product_details_container {
    max-width: calc(100vw - 32px);
  }

  .section {
    padding: 18px;
  }

  .favorite_button {
    top: 6px;
  }
}

@media (max-width: 501px) {
  .favorite_button {
    top: -10px;
  }
}

@media (max-width: 330px) {
  .product_details_container {
    max-width: calc(100vw - 32px);
  }

  .section {
    padding: 16px 6px;
  }
}
