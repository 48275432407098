.wrapper {
  padding: 40px 0 128px;
  background: #F9F9F9;
}

.main {
  max-width: 1176px;
  margin: 0 auto;
  padding: 0 16px;

  font-family: var(--roboto), sans-serif;
  font-weight: var(--semi-bold);
  letter-spacing: 0.38px;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  flex: 0 1 450px;
}

.tabs {
  display: flex;
  margin-top: 32px;
}

.tab {
  cursor: pointer;

  display: flex;
  flex: 0 0 25%;
  align-items: center;
  justify-content: center;

  padding: 16px 0;

  font-size: 20px;
  font-weight: var(--bold);
  color: #000;

  background: transparent;
  border-bottom: 3px solid #D4D4D4;
}

.tab.active {
  color: var(--color-red);
  border-color: var(--color-red);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
  justify-content: center;

  margin: 48px 0 0;
}

.item {
  display: flex;
  padding: 32px;
  background: #FFF;
  border-radius: 12px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.info {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 20px;
}

.number {
  font-size: 16px;
  line-height: 16px;
  color: #000;
}

.date {
  font-size: 15px;
  font-weight: var(--regular);
  line-height: 20px;
  color: #828282;
  letter-spacing: 0.16px;
}

.status {
  font-size: 15px;
  font-weight: var(--regular);
  line-height: 20px;
  color: #828282;
  letter-spacing: 0.16px;
}

.images {
  display: flex;
  gap: 15px;
}

.image {
  overflow: hidden;

  width: 120px;
  height: 120px;

  background: #D9D9D9;
  border-radius: 8px;
}

.image img {
  object-fit: contain;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;

  white-space: nowrap;
}

.link_details {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #FC133D;
  text-decoration-line: underline;
  letter-spacing: 0.16px;
}

.empty_message {
  width: 916px;
  margin: 80px auto 0;

  font-size: 34px;
  font-weight: 700;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.41px;
}
