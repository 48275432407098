@import '/src/common/styles/mixins';

.card {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 220px;
  max-height: 325px;
}

.link {
  color: inherit;
}

.direction {
  cursor: pointer;

  width: 100%;
  height: fit-content;
  max-height: 40px;
  margin-top: 12px;

  font-size: var(--fz-s);

  .card_title {
    font-size: var(--fz-m);
  }

  .card_description {
    overflow: hidden;
    display: inline-block;

    width: 100%;

    font-size: var(--fz-s);
    font-weight: var(--medium);
    line-height: 133%;
    text-overflow: ellipsis;
    letter-spacing: 0.16px;
    white-space: nowrap;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.price {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  margin: 8px 0 4px;

  > .rating {
    display: block;
    align-self: flex-end;

    font-size: var(--fz-xs);
    font-weight: var(--medium);
    color: var(--color-gray-medium);
  }
}

.amount {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: var(--fz-l);
  font-weight: var(--semi-bold);
  line-height: 100%;
  letter-spacing: 0.38px;
}

@include mobile {
  .card {
    max-width: 200px;
  }
}

@include mobile-small {
  .direction {
    margin: 8px 0 0;
  }

  .price {
    margin: 6px 0 4px;
    font-size: var(--fz-s);
  }
}
