@import '../styles/mixins';

@include items();

.item {
  @include item(relative);

  cursor: no-drop;
}

.item_bundles {
  @include item(relative);

  cursor: pointer;
}

.color_name {
  @include item();

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 32px;

  border: 2px solid var(--color-gray-light);
}

.color_image {
  @include item();

  min-height: 32px;
  border: 2px solid var(--color-gray-light);

  &.selected {
    border: 2px solid var(--color-red);
  }
}

.amount {
  @include amount_info(--color-gray-light);
  @include amount_elements_size_between_quantity(--color-gray-light);

  &.selected_amount {
    @include amount_info(--color-red);
    @include amount_elements_size_between_quantity(--color-red);
  }
}

.input {
  max-width: 50px;
  font-size: 15px;
}
