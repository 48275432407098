@import "../../common/styles/mixins";

@include auth_pages_form;

.verify_email_card {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  max-width: 396px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 65px;
}

.input_wrapper {
  width: 100%;
  max-width: 55px;
}

.input_item {
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

