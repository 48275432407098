.favorite {
  position: absolute;
  top: 38px;
  right: 32px;
}

.favorite_button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
}

.product_card_variant {
  width: 32px;
  height: 32px;
}

.product_variant {
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-red);
}

.active {
  fill: var(--color-red);

  circle {
    stroke: var(--color-red);
  }

  path {
    stroke: var(--color-red);
  }
}

@media (max-width: 783px) {
  .favorite {
    top: 0;
    right: 32px;
  }
}


@media (max-width: 501px) {
  .favorite {
    position: relative;
    top: 0;
    right: 0;

    grid-area: Favorite;

    width: max-content;
  }

  .product_variant {
    width: 30px;
    height: 30px;

    &::after {
      content: "Added to Favorites";

      position: absolute;
      left: 45px;

      font-size: var(--fz-m);
      color: var(--color-red);
      white-space: nowrap;
    }
  }
}