.link {
  font-size: var(--fz-m);
  text-align: left;
  text-decoration: none;
  transition: all 0.3s;

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }
}

.default {
  color: var(--color-black);

  &:hover {
    color: var(--color-red);
  }
}

.accent {
  color: var(--color-red);

  &:active {
    color: var(--color-red-dark);
  }

  &:hover,
  &:focus-visible {
    color: var(--color-red-medium);
    text-decoration: underline;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: var(--color-gray);
}

.button {
  padding: 14px;

  font-size: var(--fz-s);
  font-weight: var(--medium);
  color: var(--color-white);
  text-align: center;

  background: var(--color-red);
  border: 1px solid transparent;
  border-radius: 12px;

  &:hover {
    color: var(--color-white);
    text-decoration: none;
    background: var(--color-red-medium);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--color-red);
  }
}