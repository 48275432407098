.wrapper {
  position: relative;
  display: inline-block;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 16px 10px 15px 16px;

  font-family: Roboto, sans-serif;
  font-size: var(--fz-s);
  color: var(--color-black);
  letter-spacing: 0.24px;

  background-color: var(--color-white-ivory);
  border-radius: 12px;
  outline: none;

  transition: border 0.2s, color 0.2s;

  &:disabled {
    color: var(--color-gray-medium);
    opacity: 0.5;
  }

  &:not(.input_error):focus, &:focus-visible {
    outline: 1px solid var(--color-gray-medium);
  }
}

.input_error {
  outline: 1px solid var(--color-red);
}

.error {
  position: absolute;
  bottom: -18px;
  left: 0;

  font-size: var(--fz-xs-2);
  color: var(--color-red);
}

.button {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
}
