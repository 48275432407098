.modal_confirm_wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 100%;
}

.header_confirm {
  position: relative;
  padding: 20px 60px 10px;
}

.header_confirm {
  &::after {
    content: '';

    position: absolute;
    bottom: -10px;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    background: var(--color-white-ivory);
  }
}

.button_wrapper {
  position: relative;

  display: flex;
  gap: 12px;
  justify-content: flex-end;

  padding: 10px 10px 0;

}

.button_wrapper button {
  width: 120px;
  padding: 8px;
  border-radius: 8px;
}

.button_cancel {
  color: var(--color-black);
  background: rgba(252, 19, 61, 0.2);
}

.button_cancel {
  &:hover {
    background: rgba(252, 19, 61, 0.4);

  }
}

.button_cancel {
  &:active {
    color: var(--color-white);
    background: rgba(252, 19, 61, 0.8);
  }
}

.cross {
  cursor: pointer;

  position: absolute;
  top: -10px;
  right: 0;

  width: 16px;
  height: 16px;

  background-color: transparent;
}

.cross {
  &:hover svg path {
    fill: var(--color-red-medium);
  }
}

.cross {
  &:active svg path {
    fill: var(--color-red);
  }
}