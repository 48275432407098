.table_row {
  position: relative;
  display: table-row;
  width: 100%;
  height: 48px;

  &::before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: var(--color-white-ivory);
  }
}

.table_head {
  padding: 10px;

  font-size: var(--fz-m);
  font-weight: var(--medium);
  color: var(--color-black);
  text-align: left;

  background-color: var(--color-white);

  &[data-column='Name'] {
    width: 400px;
  }

  &[data-column='Creation Date'],
  &[data-column='Price'],
  &[data-column='Status'],
  &[data-column='Units'],
  &[data-column='Rating'],
  &[data-column='Visibility'] {
    div {
      align-items: center;
      justify-content: center;
    }
  }
}
