@import "src/common/styles/mixins";

.wrapper {
  @include underlined;

  position: relative;
  display: flex;
  align-items: center;
  height: 96px;
}

.container {
  @include content_container;

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  align-items: center;

  width: 100%;
  padding-top: calc(max(8px, min(1.8%, 26px)));
  padding-bottom: calc(max(8px, min(1.8%, 26px)));

  background-color: var(--color-white);
}
