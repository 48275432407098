.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  font-size: var( --fz-l-2);
  font-weight: var(--bold);
  font-style: normal;
  line-height: 24px;
  color: var(--color-black);
  text-align: left;
}

.header_link {
  cursor: pointer;

  font-size: var(--fz-m);
  font-weight: var(--regular);
  font-style: normal;
  line-height: 20px;
  color: var(--color-red);

  background: none;

  &:hover {
    color: var(--color-red-medium);
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
  }
}

.my_addresses_wrapper {
  margin-top: 24px;
}

.no_address {
  line-height: 20px;
  color: var(--color-black);

}

.addresses_container > div {
  width: 100%;
}
