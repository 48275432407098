@import 'src/common/styles/mixins';

.wrapper {
  background: var(--color-white-snow);
}

.content_container {
  display: grid;
  grid-gap: 12px;
  grid-template-areas:
    'personal_info business_profile notifications'
    'account_details business_profile notifications';
  grid-template-columns: minmax(auto, 30%) minmax(auto, 46%) minmax(auto, 24%);
  grid-template-rows: auto 1fr;

  max-width: var(--main-content-width);
  margin: 0 auto;
  padding: 12px 32px 16px;
}

.account_management {
  @include account_layout;
}

@mixin block_container {
  height: fit-content;
  padding: 32px;
  background: var(--color-white);
  border-radius: 12px;
}

.business_profile {
  @include block_container;

  grid-area: business_profile;
}

.notifications {
  @include block_container;

  grid-area: notifications;
}

@media only screen and (max-width: 1200px) {
  .content_container {
    grid-template-areas:
      'personal_info business_profile'
      'notifications business_profile'
      'account_details business_profile';
    grid-template-columns: minmax(auto, 40%) minmax(auto, 60%);
    padding: 12px 16px 16px;
  }
}

@media only screen and (max-width: 800px) {
  @mixin block_container {
    padding: 26px 20px 20px;
  }
}

@include mobile {
  .content_container {
    grid-template-areas:
      'personal_info personal_info '
      'business_profile business_profile'
      'notifications account_details';
    grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
  }
}

@media only screen and (max-width: 580px) {
  .content_container {
    grid-template-areas:
      'personal_info'
      'business_profile'
      'notifications'
      'account_details';
    grid-template-columns: auto;
  }
}
