.wrapper {
  display: flex;
  align-items: center;
}

.rest_filters {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;

  width: 140px;
  margin-right: 5px;

  font-family: Roboto, sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--medium);
  color: var(--color-black);

  background-color: inherit;

  transition: background-color 0.4s;
}

.vector_up,
.vector_down {
  cursor: pointer;
  margin-right: 25px;
}

.vector_down {
  transform: rotate(180deg);
}

.reset_link {
  font-size: var(--fz-s);
  color: var(--color-red);
  text-decoration-line: underline;
}
