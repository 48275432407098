@import '/src/pages/supplier-pages/pages/SupplierProducts/ProductsList/AddNewProductPage/Pricing/helper/style/mixin';
@import '/src/common/styles/mixins';

@include item();

.total_price {
  display: flex;
  gap: 16px;
}

.discount_total_wrapper {
  display: flex;
  gap: 16px;
  width: 100%;
}

@include mobile {
  .total_price {
    flex-wrap: wrap;
  }
}
