@import "src/common/styles/mixins";

.container {
  @include content_container(64px);
}

.error_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  max-width: 494px;
  margin: 0 auto;
}

.text {
  font-size: 24px;
  font-weight: 600;
  line-height: 137%;
  text-align: center;
  letter-spacing: 0.02em;
}

.info {
  font-size: 15px;
  line-height: 133%;
  text-align: center;
  letter-spacing: 0.01em;
}

.button {
  width: 219px;
}

.link {
  text-decoration: underline;

  &:hover {
    color: var(--color-red);
  }
}
