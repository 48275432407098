.product_main_info_wrapper {
  margin-bottom: 80px;
}

.product_main_info_wrapper > .grade_mobile {
  display: none;
}


@media only screen and (max-width: 1040px) {
  .product_main_info_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media only screen and (max-width: 820px) {
  .product_main_info_wrapper > .grade_mobile {
    display: flex;
    flex-basis: unset;
    padding: 16px 0;
  }
}