@import '/src/common/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 18px;
}

.wrapper {
  display: flex;
  column-gap: 36px;
  align-items: center;
}

.product_editor {
  display: flex;
  column-gap: 8px;
  align-items: center;

  font-size: var(--fz-s);
  color: var(--color-black);

  background: none;

  transition: opacity 100ms ease-in-out;

  &:hover {
    opacity: 0.75;
  }
}

.in_active_edit {
  display: none;
}

.disabled {
  cursor: auto;
  opacity: 0.6;

  &:hover {
    opacity: 0.6;
  }
}

.active {
  color: var(--color-red);
}

@media only screen and (max-width: 1430px) {
  .container {
    justify-content: flex-start;
    height: unset;
  }

  .wrapper {
    flex-direction: column-reverse;
    gap: 16px;
    align-items: flex-start;
  }
}

@media (max-width: 1025px) {
  .filter {
    font-size: var(--fz-xs-2);
  }
}

@include mobile {
  .filter {
    font-size: var(--fz-xs);
  }
}
