.wrapper {
  display: flex;
  flex-direction: column;
}

.counter {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%
}

.wrapper_input {
  width: 100%;
}

.value {
  width: 100%;
  height: 32px;

  text-align: center;

  background-color: transparent;
  border: 1px solid var(--color-gray-medium);
  border-radius: 10px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  margin-bottom: 4px;

  white-space: nowrap;
}

.label_title {
  font-size: var(--fz-m);
  font-weight: var(--regular);
  text-align: left;
}

.label_bundles {
  font-size: var(--fz-xs-2);
  color: var(--color-gray-medium);
}

.value_large {
  height: 44px;
}

.button {
  width: 32px;
  height: 32px;

  font-weight: var(--regular);
  color: var(--color-black);

  background-color: transparent;
  border: 1px solid var(--color-gray-medium);
  border-radius: 10px;

  &:focus-visible {
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }

  &:hover:not(:disabled) {
    background-color: var(--color-white-snow);
  }

  &:active:not(:disabled) {
    background-color: var(--color-gray-light);
  }
}

.button_large {
  width: 44px;
  height: 44px;
  padding: 12px 18px;
}
