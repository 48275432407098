@import 'src/common/styles/mixins';

.container {
  @include content_container(40px);
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 36px;
}

.container .link_text {
  font-size: var(--fz-l);
}

.text {
  line-height: 1.2;
}
