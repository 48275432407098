.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
}

.container_menu {
  position: relative;
  text-align: left;
}

.menu {
  position: absolute;
  z-index: 3;
  top: 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;

  padding: 32px;

  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: 0 0 8px 0 #00000040;
}
