.header_item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.checkbox_header {
  margin-right: 34px;
}

.supplier_container {
  display: flex;
  align-items: center;
}

.supplier_information {
  display: flex;
  align-items: center;
  justify-content: center;

  &.is_checkout_page {
    padding-left: 34px;
  }
}

.rating {
  display: flex;
  justify-content: center;
}

.supplier_rating {
  align-self: center;
  margin-left: 4px;
}

.supplier_link {
  display: flex;
  align-items: center;
  align-self: center;
}

.supplier_name {
  align-self: center;
  margin-left: 8px;
}

.arrow {
  transform: rotate(-90deg);
  width: 10px;
  height: 10px;
  margin-left: 8px;
}

@media (max-width: 628px) {
  .checkbox_header {
    margin-right: 16px;
  }
}
