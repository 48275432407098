@import "src/common/styles/mixins";

.wrapper {
  @include content_container;

  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: 51px;
  padding-top: 50px;
  padding-bottom: 100px;
}

.checkbox_text {
  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-l);
  font-weight: var(--regular);
  color: var(--color-black);
}

.product_title_button_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 50px;

  button {
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.review_button {
  padding-right: 50px;
  padding-left: 50px;

  font-family: var(--roboto), serif;
  font-size: var(--fz-m);
  font-weight: var(--medium);
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

