@import "../../../../../common/styles/mixins";

@include seller_section()

  .feedbacks_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.without_reviews {
  padding-top: 10px;
}

.title_container {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 23px;
}

.link {
  transform: translateY(-3px);
  align-self: flex-end;
  color: var(--color-red);
  text-decoration: underline;
}

