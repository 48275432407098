@import 'src/common/styles/mixins';

.main_page {
  background-color: var(--color-white);
}

.images_block {
  margin-bottom: 112px;
}

.container,
.status_container {
  @include content_container;
}

.main_sliders {
  display: flex;
  flex-direction: column;
  gap: 80px;

  width: 100%;
  height: fit-content;
  margin: 96px 0 116px;
}

.loading_slider {
  display: flex;
  justify-content: center;
}

@mixin laptop {
  .images_block {
    margin-bottom: 82px;
  }

  .main_sliders {
    margin: 76px 0 96px;
  }
}

@include mobile {
  .status_container {
    max-width: 100%;
    padding: 0;
  }

  .images_block {
    margin-bottom: 62px;
  }

  .main_sliders {
    gap: 40px;
    margin: 56px 0 76px;
  }
}

@include mobile-small {
  .images_block {
    margin-bottom: 48px;
  }

  .main_sliders {
    gap: 24px;
    margin: 32px 0;
  }
}
