.address {
  display: flex;
  flex-direction: column;

  width: 470px;
  padding: 16px;

  border: 1px solid var(--color-gray-light);
  border-radius: 12px;

  &:hover {
    background: #FC133D1F;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.address_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.address_personal_info {
  padding-bottom: 12px;
  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 20px;
}

.address_edit {
  cursor: pointer;
}

.address_edit:hover {
  path {
    fill: var(--color-red);
  }
}

.address_location_info {
  margin: 0 0 17px;

  font-size: var(--fz-s);
  font-weight: var(--medium);
  font-style: normal;
  line-height: 20px;

  &::before {
    content: '';

    display: block;

    height: 1px;
    margin-bottom: 12px;

    background-color: rgba(0, 0, 0, 0.14);
  }
}

.address_main {
  border: 1px solid var(--color-red);
}

.address_main_text {
  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 20px;
  color: var(--color-red);
}
