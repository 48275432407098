.table_container {
  min-width: 1376px;
  padding: 32px 30px 45px;
  background-color: var(--color-white);
  border-radius: 12px;
}

.table {
  width: 100%;
}

.header_cell {
  align-items: center;
  padding: 40px 0;
  font-size: var(--fz-m);
  font-weight: var(--medium);

  button {
    margin: 0;
  }

  &[data-column='Detail'] {
    width: 560px;
    padding-left: 16px;
  }
}

.table_data {
  padding: 16px 0;

  &[data-column='detail'] {
    position: absolute;

    overflow: hidden;
    display: block;

    width: 560px;
    height: 64px;
    padding: 15px;

    text-overflow: ellipsis;
    white-space: nowrap;

    border: 1px solid var(--color-white);

    transition: height 0.5s ease;

    &:hover {
      position: absolute;
      z-index: 2;

      height: unset;
      padding: 15px;

      white-space: wrap;

      background-color: var(--color-white);
      border: 1px solid var(--color-gray-light);
      border-radius: 5px;
    }
  }
}

.center {
  text-align: center;

  div {
    justify-content: center;
  }

  button {
    margin: 0 auto;
  }
}

.date {
  display: flex;
  flex-direction: column;
}

.empty_list {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 150px 0;
}
