@import '/src/common/styles/mixins';

.product_filter {
  width: 272px;
}

.filters_container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.button {
  width: 100%;
  margin-top: 24px;
}

@mixin laptop {
  .product_filter {
    width: 256px;
  }
}

@media only screen and (max-width: 940px) {
  .product_filter {
    width: 242px;
  }
}

@include mobile {
  .product_filter {
    width: 272px;
  }
}

@media only screen and (max-width: 430px) {
  .product_filter {
    width: 242px;
  }
}
