.list {
  display: flex;
  flex-basis: 50%;
  column-gap: 24px;
  align-items: center;

  color: var(--color-black);
}

.list_items {
  display: flex;
  column-gap: 24px;
}

.item_link {
  font-size: var(--fz-m);
  font-weight: var(--semi-bold);

  &:hover {
    font-weight: var(--bold);
    color: var(--color-red);
    transition: all 0.1s;
  }
}

.item {
  cursor: default;
}

@media (max-width: 800px) {
  .list {
    flex-basis: unset;
  }
}

@media (max-width: 373px) {
  .list {
    column-gap: 10px;
  }

  .list_items {
    display: flex;
    column-gap: 10px;
  }
}