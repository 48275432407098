.box {
  display: flex;
  flex-direction: column;

  width: 32%;
  min-height: 225px;
  margin-bottom: 16px;
  padding: 20px;

  background-color: #E3DFDF;
  border-radius: 12px;
}

.box:nth-child(10),
.box:nth-child(11) {
  width: 49%;
}

.question_text {
  padding-bottom: 8px;
  letter-spacing: 0.16px;
}

.answer_text {
  line-height: 1.3;
  letter-spacing: 0.16px;
}

@media screen and (max-width: 1000px) {
  .box,
  .box:nth-child(10),
  .box:nth-child(11) {
    width: 100%;
    min-height: fit-content;
  }
}