@import '/src/common/styles/mixins';

.wrapper .view_more_link {
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1/1;
  width: 100%;

  font-size: var(--fz-xl2);

  transition: all 0.3s ease;
}

@include mobile-small {
  .wrapper .view_more_link {
    font-size: var(--fz-l-2);
  }
}
