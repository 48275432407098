.select_input {
  font-size: 12px;
  color: var(--color-gray-medium);
  background: var(--color-white-ivory);
  border-radius: 12px;
}

.select_field_container {
  display: flex;
  gap: 16px;
  width: 100%;
}