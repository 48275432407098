.product_price_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;

  margin-bottom: 33px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 38px;
  justify-content: space-between;

  width: 100%;
}

.counter {
  width: 232px;
}

.title {
  display: flex;
  gap: 8px;
}

.total_price {
  color: var(--color-gray);
}

@media (max-width: 1040px) {
  .product_price_container {
    justify-content: space-between;
  }
}

@media (max-width: 628px) {
  .wrapper {
    width: 100%;
  }
}