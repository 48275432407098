@import 'src/pages/seller-pages/SellerCart/style/mixin';

@include line(-2px, 24px);

.item_price {
  display: flex;
  flex-direction: column;
}

.total_price {
  width: auto;
  margin-bottom: 4px;
  font-size: var(--fz-l-2);
}

.item_price_details {
  display: flex;
  align-items: center;
}

.value_bundles {
  margin-right: 4px;
  font-size: var(--fz-m);
}

.line {
  transform: translateY(-4px);

  flex: 1 1 auto;
  align-self: flex-end;

  height: 1px;
  margin-right: 4px;

  background: var(--color-black);
}

.item_prices {
  display: flex;
  align-items: center;
}

.price {
  margin-right: 4px;
  font-size: var(--fz-m);
  color: var(--color-black);
  //text-decoration: line-through;
  //text-decoration-color: var(--color-red);
}

.price_with_discount {
  font-size: var(--fz-m);
  color: var(--color-red);
}

@media (max-width: 628px) {
  .item_price_details {
    display: none;
  }
}
