.item {
  font-size: var(--fz-m);
  color: var(--color-black);
  white-space: nowrap;
  transition: .3s;

  &:active {
    color: var(--color-red-dark);
  }

  &:hover {
    color: var(--color-red-medium);
    text-decoration: underline;
  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }
}

.active_item {
  color: var(--color-red);

  &:focus-visible {
    border-radius: 4px;
    outline: 1px solid var(--color-gray-medium);
    transition: none;
  }

  &:hover {
    color: var(--color-red);
    text-decoration: none;
  }
}