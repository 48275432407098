.order_action_menu {
  left: -110px;
}

.button_more {
  margin: 0 auto;
}

.btn_action {
  display: flex;
  justify-content: start;

  width: 100%;
  padding: 0;

  font-size: 18px;
  font-weight: 400;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray-light);
}

.btn_cancel {
  color: var(--color-red);
}
