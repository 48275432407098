@import '/src/common/styles/mixins';

.wrapper {
  max-width: 440px;
  text-align: center;
}

.header {
  font-size: var(--fz-xl);
  font-weight: var(--bold);
  line-height: 135%;
  color: var(--color-black);
  letter-spacing: 0.41px;
}

.subheader {
  margin-top: 16px;

  font-size: var(--fz-s);
  line-height: 133%;
  color: #636568;
  letter-spacing: 0.16px;
}

@include mobile {
  .message {
    font-size: var(--fz-l-2);
    font-weight: var(--semi-bold);
    line-height: 24px;
  }
}
