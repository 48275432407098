.item_description {
  display: flex;
  gap: 26px;
  width: 100%;
  padding-left: 34px;
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 8px;
}

.item_information {
  display: flex;
  flex-direction: column;
}

.item_title {
  margin-bottom: 12px;
  font-size: var(--fz-m);
  font-weight: var(--medium);
}

.item_details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 24px;
}

.item_color,
.item_size {
  margin-right: 12px;
  margin-bottom: 4px;
  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.item_pieces {
  margin-bottom: 4px;
  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.order_details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

@media (max-width: 628px) {
  .item_description {
    gap: 8px;
    padding-left: 16px;
  }
}
