@import "src/common/styles/mixins";

.container {
  @include content_container;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 40px;
}

.logo {
  grid-column-end: 2;
  grid-column-start: 1;
}

.nav_container {
  grid-column-end: 3;
  grid-column-start: 2;
  column-gap: 48px;
  justify-content: center;

  margin: 0 10px;

  border-right: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
}

.selects {
  grid-column-end: 4;
  grid-column-start: 3;
}

@media only screen and (max-width: 1280px) {
  .container {
    .nav_container {
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 10px;
      grid-template: repeat(2, 1fr) / repeat(3, 0.5fr);
      grid-template-columns: repeat(3, 0.5fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px 20px;
      justify-items: center;
    }
  }
}


@media only screen and (max-width: 1030px) {
  .container {
    .nav_container {
      display: grid;
      grid-template: repeat(3, 1fr) / repeat(2, 1fr);
      gap: 5px 0;

      border-right: none;
      border-left: none;
    }
  }
}

@media only screen and (max-width: 820px) {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 20px 10px;

    h1 {
      flex-basis: 20%;
      order: -1;
    }

    div:has(.nav_container) {
      display: flex;
      justify-content: center;
      order: 5;

      width: 100%;

      border-right: none;
      border-left: none;
    }

    .nav_container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 15px 50px;
    }


    div:has(.selects) {
      display: flex;
      flex-basis: 20%;
      gap: 0;
      order: 3;
    }
  }
}

@media only screen and (max-width: 580px) {
  .container {
    flex-direction: column;
    max-width: 420px;

    h1, div:has(.selects), div:has(.nav_container) {
      flex-basis: auto;
      order: -1;
    }

    h1 {
      align-self: start;
      margin: 0 0 10px 32px;
    }

    div:has(.nav_container) {
      justify-content: flex-start;
      max-width: 355px;

    }

    .nav_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 15px 20px;
      justify-items: start;

    }
  }
}

@media only screen and (max-width: 580px) {
  .container {
    align-items: flex-start;
    max-width: 320px;

    h1 {
      margin: 0 0 12px;
    }

    .nav_container {
      gap: 15px 0;
      margin: 0;
    }

    .selects {
      gap: 15px;

      div {
        z-index: 999;
        gap: 0;
        width: 120px !important;
      }

      div {
        display: flex;
        padding: 0 3px 0 0;
      }

      ul {
        z-index: 999;
        bottom: 35px;
      }
    }
  }
}