.review_modal {
  width: 750px;
  min-height: 520px;
  padding: 30px;

  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--regular);
  color: var(--color-gray-medium);
}

.leave_title {
  margin-bottom: 25px;

  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-xl);
  font-weight: var(--semi-bold);
  color: var(--color-black);
}

.rate_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 30px;
}

.rate_delivery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 80px;
}

.rate_order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span {
    margin-bottom: 20px;
  }
}

.grades {
  cursor: pointer;
  padding: 0;
}

.review_photos_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 30px;
}

.review_text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.review_text {
  resize: none;

  width: 100%;
  min-height: 45px;
  margin-bottom: 16px;
  padding: 16px 14px;

  font-family: Roboto, sans-serif;
  font-size: var(--fz-s);
  color: var(--color-black);
  letter-spacing: 0.24px;

  background: var(--color-white-ivory);
  border-radius: 12px;
}

.review_button {
  width: 100%;
}