.delivery_info {
  display: flex;
  gap: 24px;
  align-items: center;

  padding: 12px 32px;

  background: var(--color-red-light);
  border-radius: 8px;
}

.delivery_time,
.delivery_method {
  display: flex;
}

@media (max-width: 1040px) {
  .delivery_info {
    gap: 12px;
    padding: 12px 16px;
  }

  .delivery_method {
    text-align: right;
  }
}
