@import '../styles/mixins';

@include items();

.item {
  @include item(relative);

  cursor: pointer;
}

.color_name {
  @include item();

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 32px;

  border: 2px solid var(--color-gray-light);
}

.color_image {
  @include item(relative);

  min-height: 32px;
}

.item::before {
  content: '';

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 32px;

  border: 2px solid var(--color-gray-light);
  border-radius: 8px;
}

.active::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 32px;

  border: 2px solid var(--color-red);
  border-radius: 8px;
}

