@import '/src/common/styles/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  font-size: var(--fz-xs-2);
  letter-spacing: -0.08px;
}

@include mobile {
  .wrapper {
    justify-content: space-evenly;
  }
}

@include mobile-small {
  .wrapper {
    display: grid;
    grid-row-gap: 6px;
    grid-template-columns: 130px 90px;
    grid-template-rows: 1fr 1fr;
  }

  .wrapper > * {
    justify-self: start;
  }
}
