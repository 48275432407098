.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.pointer_none {
  pointer-events: none;
}

.content {
  display: grid;
  flex: 1 1 auto;
  align-content: center;
  justify-items: center;

  margin: 10vh 0;
}

.subtitle {
  margin: 18px 0 0;
  font-size: var(--fz-s);
  font-weight: var(--regular);
  color: var(--color-gray-dark);
}

.footer_wrapper {
  display: flex;
  gap: 40px;
}
