@import 'src/common/styles/mixins';

.container {
  @include content_container(40px);
}

.title_block {
  margin: 24px 0 12px;
}

.text {
  margin-bottom: 8px;
  line-height: 120%;
}

@include mobile {
  .title_block {
    margin: 16px 0;
  }

  .text {
    line-height: 20px;
  }
}
