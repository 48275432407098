@import '/src/common/styles/mixins';

.description_wrapper {

  @include mobile {
    width: 80%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 634px;
  margin: 0 auto;
  padding-top: 48px;
}

.description_list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
}

.title {
  margin-bottom: 16px;
  line-height: 34px;
}

.paragraph {
  margin-bottom: 40px;
}

.description_item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.arrow {
  cursor: pointer;
  transition: transform 0.5s;
}

.arrow_up {
  transform: rotate(180deg);
}

@include mobile {
  .description_wrapper {
    width: 80%;
  }
}
