@import 'src/common/styles/mixins';

.container {
  width: 100%;
  height: fit-content;
}

.inner {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 36px;
}

.title_box {
  display: flex;
  gap: 24px;
  align-items: flex-end;
}

.title {
  color: var(--color-black);
}

.link {
  margin-bottom: 5px;

  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-m);
  font-weight: var(--regular);
  line-height: 20px;
  color: var(--color-red);
  text-decoration: underline;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
}

.buttons button {
  cursor: default;
}

.icon_left,
.icon_right {
  cursor: pointer;
  transform: rotate(90deg);
  width: 18px;
  height: 18px;

  &:hover {
    fill: var(--color-red);
  }

  &:active {
    fill: var(--color-red-medium);
  }
}

.icon_right {
  transform: rotate(270deg);
}

.disable_button {
  cursor: default;

  &:hover {
    fill: var(--color-gray-light);
  }
}

.disable_button path {
  pointer-events: none;
  fill: var(--color-gray-light);
}

@include mobile {
  .inner {
    margin-bottom: 24px;
  }
}

@include mobile-small {
  .inner {
    margin-bottom: 16px;
  }
}
