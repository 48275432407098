.modal {
  pointer-events: all;

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  overflow-y: scroll;
  display: flex;
  justify-content: center;

  width: 100vw;
  height: 100vh;
  padding: 30px 0;

  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);

  transition: 0.5s;
}

.modal_content {
  width: max-content;
  height: max-content;
  margin: auto 0;
  padding: 32px;

  background: #FFF;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  transition: 0.5s all;
}

@media only screen and (max-width: 430px) {
  .modal_content {
    padding: 26px;
  }
}