@import '/src/common/styles/mixins';

.product_page_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

@media (max-width: 820px) {
  .product_page_wrapper > .grade {
    display: none;
  }

  .favorite {
    position: unset;
    top: unset;
    left: unset;
  }
}

@include mobile {
  .product_page_wrapper {
    padding: 30px 0 0;
  }
}

@media (max-width: 501px) {
  .product_page_wrapper {
    flex-direction: column;
    gap: 30px;
  }

  .favorite::after {
    left: 56px;
  }
}
