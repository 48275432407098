.header {
  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  color: var(--color-black);
  text-align: center;
  letter-spacing: 0.0024em;
}

.subheader {
  margin-top: 16px;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 24px;
  color: #636568;
  text-align: center;
  letter-spacing: 0.16px;
}

.modal_content_wrapper {
  width: 420px;
  text-align: left;
}

.modal_header {
  margin-bottom: 26px;

  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  line-height: 41px;
  color: var(--color-black);
  letter-spacing: 0.41px;
}

.modal_sub_header {
  margin-bottom: 32px;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 24px;
  color: var(--color-black);
  letter-spacing: 0.16px;
}

.button_modal {
  width: 100%;
}