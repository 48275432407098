@import "src/common/styles/mixins";

@include auth_pages_form;

.input_wrapper {
  input {
    padding-right: 46px;
  }
}

.buttons_container_alternate_signin {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-self: center;

  margin-bottom: 24px;
}