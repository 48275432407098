@import 'src/common/styles/mixins';

.container {
  @include content_container(40px);
}

.wrapper {
  margin-top: 36px;
}

@include mobile {
  .container {
    margin: 16px auto;
  }

  .title {
    margin-bottom: 16px;
  }

  .footer {
    display: none;
  }
}
