.select_and_pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  padding-bottom: 36px;
}

.inner_block_wrapper {
  display: flex;
  gap: 30px;
  align-items: flex-end;
}