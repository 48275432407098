.phone_number {
  position: relative;
  margin: 20px 0;
}

.phone_number .phone {
  width: calc(100% - 47px);
  height: 100%;
  margin-left: 46.5px;
  padding: 12px 10px 15px;

  font-size: var(--fz-s);
  color: var(--color-black);

  background-color: var(--color-white-ivory);
  border: 1px solid transparent;
  border-left: none !important;
  border-radius: 0 12px 12px 0;

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: var(--color-gray-medium);
    opacity: 0.5;
  }
}

.phone_number .phone_error {
  border: 1px solid var(--color-red);
}

/*!important is used here to override styles from 'react-phone-input-2' library for hover events and open country code dropdown menu*/

.phone_number .phone_flag {
  padding: 12px 4px 15px;

  color: var(--color-black);

  background-color: var(--color-white-ivory) !important;
  border: 1px solid transparent;
  border-right: none !important;
  border-radius: 12px 0 0 12px !important;
}

.phone_number .phone_flag_error {
  border: 1px solid var(--color-red);
  border-right: none;
}

.phone_number .phone_flag div, .phone_number .phone_flag div:hover {
  background-color:  var(--color-white-ivory) !important;
}

.error {
  position: absolute;
  bottom: -16px;
  left: 0;

  font-size: var(--fz-xs);
  color: var(--color-red);
}

.phone_flag_disabled {
  opacity: .5;
}
