@import '/src/common/styles/mixins';

.header {
  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  color: var(--color-black);
  letter-spacing: 0.0024em;
}

.subheader {
  margin-top: 16px;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 24px;
  color: #636568;
  letter-spacing: 0.16px;
}

.modal_content_wrapper {
  width: 420px;
  text-align: left;
}

.modal_header {
  margin-bottom: 26px;

  font-size: var(--fz-xxl);
  font-weight: var(--bold);
  line-height: 41px;
  color: #000;
  letter-spacing: 0.41px;
}

.modal_sub_header {
  margin-bottom: 32px;

  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 24px;
  color: var(--color-black);
  letter-spacing: 0.16px;
}

.modal_window_btn_active {
  width: 100%;
}

@include mobile {
  .header {
    font-size: var(--fz-l-2);
    font-weight: var(--semi-bold);
    line-height: 24px;
  }

  .modal_container {
    margin: 0 8px;
    padding: 16px;
  }

  .modal_content_wrapper {
    width: 100%;
  }

  .modal_header {
    margin-bottom: 16px;
    font-size: var(--fz-l-2);
    line-height: 24px;
  }

  .modal_sub_header {
    margin-bottom: 24px;
  }
}
