@import 'src/common/styles/mixins';

.image_container {
  height: 200px;
}

.content_container {
  @include content_container;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 60px auto;
}

.title {
  line-height: 1.2;
}

.contacts {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

@include mobile {
  .content_container {
    margin: 58px auto;
  }

  .contacts {
    margin-bottom: 80px;
  }
}

@include mobile-small {
  .image_container {
    height: 162px;
  }

  .content_container {
    margin: 48px auto;
  }

  .contacts {
    margin-bottom: 60px;
  }
}
