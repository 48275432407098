@import "src/common/styles/mixins";

@include seller_section();

.description_container {
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  background: var(--color-white);
  border-radius: 12px;
}

.description {
  max-width: 551px;
  line-height: 20px;
}