@import '/src/common/styles/mixins';

.item {
  display: flex;
  gap: 16px;
  align-items: center;

  margin-bottom: 16px;
  padding: 24px;

  background-color: var(--color-white-snow);
  border-radius: 12px;
}

.number {
  font-size: var(--fz-xxxl);
  font-weight: var(--bold);
  line-height: 117%;
  color: rgba(252, 19, 61, 0.32);
  letter-spacing: 0.41px;
}

.title {
  padding-bottom: 4px;
  letter-spacing: 0.16px;
}

@include mobile {
  .item {
    margin-bottom: 10px;
    padding: 16px;
  }

  .title {
    font-size: var(--fz-l);
  }
}
