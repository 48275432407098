@import '/src/common/styles/mixins';

.table_row {
  position: relative;
  display: table-row;
  width: 100%;

  &::before {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: var(--color-white-ivory);
  }
}

.table_deactivated {
  .table_td:not(:first-child) {
    opacity: 0.4;
  }
}

.table_td {
  max-height: 48px;
  padding: 10px;

  font-size: var(--fz-m);
  font-weight: var(--regular);
  color: var(--color-black);

  background-color: var(--color-white);

  &[data-column='Name'] {
    overflow: hidden;
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-column='Creation Date'] {
    display: flex;
    justify-content: center;
  }

  &[data-column='Price'],
  &[data-column='Status'],
  &[data-column='Units'],
  &[data-column='Visibility'] {
    text-align: center;
  }

  &[data-column='Rating'] {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.datetime_container {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  width: max-content;
}

@include mobile {
  .image {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}
