.wrapper {
  display: flex;
  column-gap: 8px;
}

.default_icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: var(--color-gray-medium);
}

.active_layout {
  color: var(--color-red);
}
