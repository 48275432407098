@import '/src/common/styles/mixins';

.container {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
}

.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 24px;
  height: 24px;
  padding: 2px;

  font: var(--fz-m);
  font-weight: var(--semi-bold);

  background: none;
  border-radius: 2px;

  transition: color 0.2s;

  &:focus-visible:not(.disabled) {
    outline: 2px solid var(--color-red-dark);
  }

  &:hover:not(.active):not(.disabled):not(.is_disabled_dot) {
    color: var(--color-red-dark);
    text-decoration: underline var(--color-red-dark);
  }

  &:hover:not(.disabled) svg {
    fill: var(--color-red-dark);
  }
}

.active {
  color: var(--color-red);
}

.disabled {
  cursor: default;
  opacity: 0.5;
  outline: none;
}

.is_disabled_dot {
  cursor: default;
  outline: none;
}

.arrow {
  width: 14px;
  height: 14px;
  fill: var(--color-black);
}

.disabled .arrow {
  fill: var(--color-gray-medium);
}

.arrow_left {
  rotate: 90deg;
}

.arrow_right {
  rotate: -90deg;
}

@include mobile {
  .button {
    min-width: 20px;
    padding: 0;
  }
}

@include mobile-small {
  .button {
    min-width: 16px;
  }
}
