.category_wrapper {
  padding-top: 33px;
}

.category_list_item {
  display: flex;
  gap: 8px;
  padding-bottom: 4px;

  > * {
    cursor: pointer;
  }
}

.title {
  cursor: pointer;
  font-size: var(--fz-m);
  font-weight: var(--regular);
}

.arrow {
  margin-top: 3px;
  transition: transform 0.5s;
}

.arrow_up {
  transform: rotate(180deg);
}

.bread_crumbs {
  margin-bottom: 22px;
  color: var(--color-red);

  a {
    cursor: default;
    font-weight: var(--regular);
  }
}

