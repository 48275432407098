.sliders_container {
  position: sticky;
  top: 0;

  float: left;
  display: flex;
  flex-basis: 50%;
  flex-direction: row;
  gap: 3px;
  align-items: flex-start;
  justify-content: space-between;

  width: 50%;
  margin-right: 30px;
}

.swiper_second_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: space-between;

  width: 13%;
  min-height: min-content;
  max-height: 585px;
}

.swiper_second {
  width: 100%;
  min-height: min-content;
}

.swiper_second_el {
  align-self: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 89px;
  max-height: 89px;

  border-radius: 8px;
}


.swiper_second_el img {
  box-sizing: border-box;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.active_swipe {
  border: 2px solid var(--color-red);
}

.swiper_main_container {
  width: 585px;
  height: 585px;
}

.swiper_main {
  width: 585px;
  height: 585px;
}

.swiper_main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.btn {
  padding: 5px;
  transition: opacity 0.3s ease-in-out;

  &:disabled {
    cursor: unset;
  }

  &:hover:not(.disabled) {
    opacity: .6;
  }
}

.arrow {
  fill: var(--color-black);
  transition: fill 0.1s ease-in-out;
}

.disabled > .arrow {
  opacity: 0.5;
}

.arrow_up {
  transform: rotate(180deg);
}

@media only screen and (max-width: 1440px) {
  .sliders_container {
    position: unset;

    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    justify-content: center;
  }

  .swiper_second_wrapper {
    flex-direction: row;
    gap: 8px;
    order: 2;
  }

  .swiper_main_container {
    width: 100%;
    height: auto;
  }

  .swiper_main {
    width: 585px;
    height: 585px;
  }

  .swiper_second_wrapper {
    width: 585px;
  }

  .rotate_first {
    transform: rotate(90deg);
  }

  .rotate_second {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-width: 1240px) {
  .swiper_main {
    width: 490px;
    height: 490px;
  }

  .swiper_second_wrapper {
    width: 490px;
  }
}

@media only screen and (max-width: 1040px) {
  .sliders_container {
    flex-direction: row-reverse;
    row-gap: 50px;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .swiper_second_wrapper {
    flex-direction: column;
  }

  .swiper_main_container {
    width: 585px;
    height: 585px;
  }

  .swiper_main {
    width: 585px;
    height: 585px;
  }

  .swiper_second_wrapper {
    width: auto;
    margin-right: 50px;
  }

  .rotate_first {
    transform: rotate(180deg);
  }

  .rotate_second {
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  .sliders_container {
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
  }

  .swiper_second_wrapper {
    flex-direction: row;
    width: 585px;
    margin: 0;
  }

  .swiper_second {
    width: min-content;
    max-width: 100%;
    margin-left: 0;
  }

  .rotate_first {
    transform: rotate(90deg);
  }

  .rotate_second {
    transform: rotate(-90deg);
  }


  .swiper_second_el {
    max-width: 90px;
    max-height: 90px;
  }
}

@media (max-width: 620px) {
  .swiper_main_container {
    width: 480px;
    height: 480px;
  }

  .swiper_main {
    width: 480px;
    height: 480px;
  }

  .swiper_second_wrapper {
    width: 480px;
  }

  .swiper_second_el {
    max-width: 90px;
    max-height: 90px;
  }
}

@media (max-width: 550px) {
  .sliders_container {
    row-gap: 8px;
  }

  .swiper_main_container {
    width: 420px;
    height: 420px;
  }

  .swiper_main {
    width: 420px;
    height: 420px;
  }

  .swiper_second_wrapper {
    width: 420px;
  }


  .swiper_second_el {
    max-width: 75px;
    max-height: 75px;
  }
}

@media (max-width: 460px) {
  .swiper_main_container {
    width: 380px;
    height: 380px;
  }

  .swiper_main {
    width: 380px;
    height: 380px;
  }

  .swiper_second_wrapper {
    width: 380px;
  }

  .swiper_second_el {
    max-width: 92px;
    max-height: 92px;
  }
}

@media (max-width: 415px) {
  .swiper_main_container {
    width: 320px;
    height: 320px;
  }

  .swiper_main {
    width: 320px;
    height: 320px;
  }

  .swiper_second_wrapper {
    width: 320px;
  }

  .swiper_second_el {
    max-width: 73px;
    max-height: 73px;
  }
}

@media (max-width: 355px) {
  .swiper_main_container {
    width: 300px;
    height: 300px;
  }

  .swiper_main {
    width: 300px;
    height: 300px;
  }

  .swiper_second_wrapper {
    width: 300px;
  }

  .swiper_second_el {
    max-width: 66px;
    max-height: 66px;
  }
}

@media (max-width: 335px) {
  .swiper_main_container {
    width: 280px;
    height: 280px;
  }

  .swiper_main {
    width: 280px;
    height: 280px;
  }

  .swiper_second_wrapper {
    width: 280px;
  }

  .swiper_second_el {
    max-width: 200px;
    max-height: 200px;
  }
}
