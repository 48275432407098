.loader,
.loader_min {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: max-content;
  min-height: 100vh;

  background-color: #00000080;
}

.loader_min {
  background: none;
}

.loader_indicator,
.loader_indicator_min {
  width: 80px;
  height: 80px; /* Safari */

  border: 10px solid var(--color-white);
  border-top: 10px solid var(--color-gray-light);
  border-radius: 50%;

  animation: spin 2s linear infinite;
}

.loader_indicator_min {
  width: 50px;
  height: 50px; /* Safari */
  border: 5px solid var(--color-white);
  border-top: 5px solid var(--color-gray-light);
}

.loader_local_indicator {
  border: 10px solid var(--color-gray-light);
  border-top: 10px solid var(--color-gray-medium);
}

/* Safari */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
