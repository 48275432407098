@import '/src/common/styles/mixins';

.button {
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 14px;

  font-family: var(--roboto), sans-serif;
  font-size: var(--fz-s);
  font-weight: var(--medium);

  border: 1px solid transparent;
  border-radius: 12px;
  outline: none;

  transition: all 0.5s;

  &:focus-visible {
    outline: 1px solid var(--color-black);
    transition: none;
  }
}

.default,
.red {
  color: var(--color-white);
  background: var(--color-red);

  &:hover {
    background: var(--color-red-medium);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      var(--color-red);
  }
}

.default:disabled {
  cursor: initial;
  color: var(--color-gray-light);
  background: var(--color-white);
  border: 1px solid #E3E3E3;
}

.red:disabled {
  cursor: initial;
  background: rgba(252, 19, 61, 0.2);
}

.light_red {
  color: var(--color-black);
  background: rgba(252, 19, 61, 0.2);

  &:hover:not(:disabled) {
    background: rgba(252, 19, 61, 0.4);
  }

  &:active:not(:disabled) {
    color: var(--color-white);
    background: rgba(252, 19, 61, 0.8);
  }

  &:disabled {
    cursor: initial;
    color: var(--color-gray);
  }
}

.white {
  color: var(--color-black);
  background: var(--color-white);

  &:disabled {
    cursor: initial;
  }
}

.black {
  color: var(--color-white);
  background: var(--color-black);

  &:disabled {
    cursor: initial;
  }
}

.outline {
  display: flex;
  flex-basis: 49%;
  align-items: center;
  justify-content: center;

  padding: 30px 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 130%;
  color: var(--color-red);

  background-color: inherit;
  border: 1px solid var(--color-gray-light);
  border-radius: 12px;

  transition: none;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 975px) {
  .outline {
    flex-basis: 100%;
  }
}

@include mobile {
  .button {
    padding: 12px;
  }
}
