@import '/src/common/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-top: 48px;
}

.inner_wrapper {
  display: flex;
  align-items: center;
}

.search {
  flex-grow: 1;
  margin-right: 24px;
  border-radius: 12px;

  input {
    background-color: var(--color-white);
  }
}

@include mobile {
  .inner_wrapper {
    flex-direction: column;
    gap: 30px;
    align-items: unset;
  }
}
