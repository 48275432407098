@import '/src/common/styles/mixins';

.title {
  margin-bottom: 24px;
  color: var(--color-black);
}

.networks_list {
  display: flex;
  gap: 12px;
}

.network {
  width: 44px;
  height: 44px;

  background: linear-gradient(180deg, #F4F4F4 0%, #DEDEDE 100%);
  border-radius: 12px;

  transition: all 0.4s;

  &:hover {
    transform: scale(1.2);
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  &:focus-visible {
    border-radius: 12px;
    outline: 1px solid var(--color-gray-medium);
  }
}

@include mobile-small {
  .title {
    margin-bottom: 16px;
    white-space: nowrap;
  }
}
