@import '/src/common/styles/mixins';

.menu_container {
  position: absolute;
  z-index: 100;
  top: 52px;
  left: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 22px;

  min-width: 600px;
  min-height: 464px;
  padding: 22px 22px 22px 12px;

  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}

.list {
  display: flex;
  flex-direction: column;
}

@mixin laptop {
  .menu_container {
    top: 108px;
    left: 20px;
    flex-direction: column;
    row-gap: 32px;
  }
}

@include mobile {
  .menu_container {
    top: 52px;
    left: 0;

    width: calc(100vw - 20px);
    min-width: auto;
    margin: 0 10px;
  }
}
