.address_form {
  min-width: 600px;
}

.address_form_header {
  position: relative;

  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 16px;
}

.address_form_checkbox {
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.checkbox {
  font-size: var(--fz-s);
  font-weight: var(--regular);
  line-height: 20px;
}

.address_form_block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.address_form_block_title {
  margin-bottom: 4px;
}

.address_form_block_row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

}

.address_form_item {
  width: 100%;
  font-size: var(--fz-s);
  font-weight: var(--regular);
}

.address_form_input {
  display: block;
}

.address_form_button {
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.select {
  font-size: var(--fz-s);
  background: #F2F2F2;
  border-radius: 12px;
}

.delete_address {
  cursor: pointer;

  display: flex;
  gap: 12px;
  align-items: center;

  background: transparent;
}
